import React from 'react';

import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

import { blue } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';

import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';

import axios from 'axios';

import ActiveBotImg from '../../../../icons/Robo_Active.png';
import IdleBotImg from '../../../../icons/Robo_Idle.png';

import { useUserContext } from '../../../hooks/useUserContext';
import { ToInfinityAndBeyond } from '../../__common/scrollable/infinity-scroll';

const useStyles98273592 = makeStyles(theme => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
    backgroundColor: '#202447'
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  }
}));

const SearchCmp = ({ input, ...rest }) => {
  const classes = useStyles98273592();

  return (
    <>
      <IconButton
        type="submit"
        className={classes.iconButton}
        aria-label="search"
      >
        <SearchIcon />
      </IconButton>

      <InputBase
        color="primary"
        placeholder="Search..."
        classes={classes.input}
        inputProps={{ 'aria-label': 'search' }}
      />
      <Divider className={classes.divider} orientation="vertical" />
    </>
  );
};

const AgentListItem = ({ element, state, onChange, ...rest }) => (
  <>
    <ListItem key={element.id} button>
      <ListItemIcon style={{ color: 'white' }}>
        {element.isAttached ? (
          <img className="botImg" src={ActiveBotImg} alt="Robo active icon" />
        ) : (
          <img className="botImg" src={IdleBotImg} alt="Robo active icon" />
        )}
      </ListItemIcon>
      <ListItemText id={element.id} primary={element.name} />
      <ListItemSecondaryAction>
        <Checkbox
          checked={state.checkedAgents.indexOf(element) !== -1}
          disabled={
            element.isRunning ||
            element.id ===
              (state.processingAgent?.id ??
                '00000000-0000-0000-0000-000000000000')
          }
          onChange={() => onChange(element)}
        />
      </ListItemSecondaryAction>
    </ListItem>
  </>
);

const actionDescriptor = {
  ON_FETCHED: 'ON_FETCHED',
  ON_FETCH_MORE: 'ON_FETCH_MORE',

  ON_CHANGE: 'ON_CHANGE',
  ON_CHANGED: 'ON_CHANGED'
};

const initialState = {
  loading: true,

  pageNo: 1,
  pageSize: 5,
  hasMore: false,
  totalCount: 0,

  processingAgent: null,

  agents: [],

  checkedAgents: [],

  error: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionDescriptor.ON_FETCHED: {
      return {
        ...state,
        loading: false,
        agents: state.agents.concat(action.payload.agents),
        checkedAgents: state.checkedAgents.concat(
          action.payload.agents.filter(agent => agent.isAttached)
        ),
        totalCount: action.payload.totalCount,
        hasMore: action.payload.totalCount > state.agents.length
      };
    }

    case actionDescriptor.ON_FETCH_MORE: {
      return {
        ...state,
        pageNo: state.pageNo + 1
      };
    }

    case actionDescriptor.ON_CHANGE: {
      return {
        ...state,
        checkedAgents: action.payload.updatedList,
        processingAgent: action.payload.agent
      };
    }
    case actionDescriptor.ON_CHANGED: {
      return {
        ...state,
        processingAgent: null
      };
    }
  }
};

const useStyles02918359827 = makeStyles(theme => ({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600]
  },
  root: {
    width: '100%',
    backgroundColor: '#050A31'
  }
}));

export const AvailableAgentsDialog = ({
  bot,
  open,
  onClose,
  onAddAgent,
  onRemoveAgent,
  ...rest
}) => {
  const classes = useStyles02918359827();
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const { accessToken } = useUserContext();

  React.useEffect(() => {
    let cancellationToken;

    axios({
      method: 'GET',
      url: `${process.env.REACT_APP_TAP_NEXUS}/api/bots/${
        bot.id
      }/agents?skip=${(state.pageNo - 1) * state.pageSize}&take=${
        state.pageSize
      }`,
      cancelToken: new axios.CancelToken(
        canceler => (cancellationToken = canceler)
      ),
      headers: {
        'CONTENT-TYPE': 'application/json',
        AUTHORIZATION: `Bearer ${accessToken}`
      }
    })
      .then(response => {
        dispatch({
          type: actionDescriptor.ON_FETCHED,
          payload: {
            agents: response.data.value.map(agent => {
              return {
                id: agent.id,
                name: agent.name,
                isAttached: agent.isAttached,
                isRunning: agent.isRunning
              };
            }),
            totalCount: response.data.count
          }
        });
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          alert(error);
          return;
        }
      });

    return () => {
      cancellationToken();
    };
  }, [state.pageNo, bot.id]);

  const onChangeHandler = async agent => {
    const index = state.checkedAgents.indexOf(agent);
    const updatedCheckedList = [...state.checkedAgents];

    if (index === -1) {
      updatedCheckedList.push(agent);

      dispatch({
        type: actionDescriptor.ON_CHANGE,
        payload: {
          updatedList: updatedCheckedList,
          agent: agent
        }
      });

      await onAddAgent(bot.id, agent.id, agent.name);
    } else {
      updatedCheckedList.splice(index, 1);

      dispatch({
        type: actionDescriptor.ON_CHANGE,
        payload: {
          updatedList: updatedCheckedList,
          agent: agent
        }
      });

      await onRemoveAgent(bot.id, agent.id);
    }

    dispatch({
      type: actionDescriptor.ON_CHANGED,
      payload: {
        updatedList: updatedCheckedList
      }
    });
  };

  return (
    <Dialog open={open} onClose={() => onClose(false)}>
      {/* DIALOG HEADER */}
      {/* <DialogTitle style={{ backgroundColor: '#202447' }}>
        <SearchCmp />
      </DialogTitle> */}

      {/* DIALOG BODY */}
      <List dense className={classes.root} style={{ height: '200px' }}>
        <ToInfinityAndBeyond
          style={{ backgroundColor: '#050A31' }}
          hasMore={state.hasMore}
          loading={state.loading}
          source={state.agents}
          totalCount={state.totalCount}
          renderedComponent={
            <AgentListItem state={state} onChange={onChangeHandler} />
          }
          onFetchMore={() => {
            dispatch({
              type: actionDescriptor.ON_FETCH_MORE
            });
          }}
        />
      </List>
    </Dialog>
  );
};
