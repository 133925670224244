import React from 'react';

import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/AddCircle';

import { AvailableAgentsDialog } from './bot-tbl.add-agent.v-dialog';

export const AssignToAgentBtn = ({
  bot,
  onAddAgent,
  onRemoveAgent,
  ...rest
}) => {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      {' '}
      {open ? (
        <AvailableAgentsDialog
          bot={bot}
          open={open}
          onClose={() => setOpen(false)}
          onAddAgent={onAddAgent}
          onRemoveAgent={onRemoveAgent}
        />
      ) : (
        <></>
      )}
      <Fab
        size="small"
        color="secondary"
        aria-label="add"
        style={{ color: '#C05EC3', backgroundColor: 'transparent' }}
        onClick={() => setOpen(true)}
      >
        <AddIcon />
      </Fab>
    </>
  );
};
