import React from 'react';

import { withStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import PauseCircleFilledOutlinedIcon from '@material-ui/icons/PauseCircleFilledOutlined';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';

import botImg from '../../../../icons/Robo_Active.png';
import botImg_idle from '../../../../icons/Robo_Idle.png';

import { ActionsInnerMoreMenuActions } from './agent-tbl.more-menu.v-dialog.more-inner-menu';

const BotNameCmp = ({ name, isRunning, ...rest }) => {
  return (
    <>
      {isRunning ? (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img className="botImg" src={botImg} alt="Robo active icon" />{' '}
          <div>{name}</div>
        </div>
      ) : (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img className="botImg" src={botImg_idle} alt="Robo active icon" />
          <div>{name}</div>
        </div>
      )}
    </>
  );
};

const BotStatusCmp = ({ isRunning, ...rest }) => {
  return (
    <>
      {isRunning ? (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <CheckCircleRoundedIcon
            style={{ color: '#32D74C', marginRight: '5px' }}
          />
          <div>Running</div>
        </div>
      ) : (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <PauseCircleFilledOutlinedIcon
            style={{ color: '#202447', marginRight: '5px' }}
          />
          <div>Idle</div>
        </div>
      )}
    </>
  );
};

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: '#202447'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

export const ActionsMenuDialogCmp = ({
  open,
  agent,
  onClose,
  onStop,
  onStart,
  onRemove,
  ...rest
}) => {
  return (
    <div>
      <Dialog
        open={open}
        onClose={() => onClose()}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle id="customized-dialog-title" onClose={() => onClose()}>
          <p
            style={{
              fontSize: '2rem',
              color: '#B063A7',
              lineHeight: '60px',
              fontWeight: '600'
            }}
          >
            {agent.name}
          </p>
        </DialogTitle>

        <DialogContent
          dividers
          style={{ backgroundColor: '#050A31', padding: '0', height: '350px' }}
        >
          <TableContainer
            component={Paper}
            style={{ backgroundColor: '#050A31' }}
          >
            <Table aria-label="custom pagination table">
              <TableBody>
                {agent.bots.map(bot => (
                  <TableRow key={bot.id} className="tbrow">
                    <TableCell component="th" scope="row" align="left">
                      <BotNameCmp isRunning={bot.isRunning} name={bot.name} />
                    </TableCell>
                    <TableCell style={{ width: 160 }} align="right">
                      <BotStatusCmp isRunning={bot.isRunning}/>
                    </TableCell>
                    <TableCell component="th" scope="row" align="right">
                      <ActionsInnerMoreMenuActions
                        agent={agent}
                        isRunning={bot.isRunning}
                        onStop={() => onStop(agent.id, bot.id)}
                        onStart={() => onStart(agent.id, bot.id)}
                        onRemove={() => onRemove(agent.id, bot.id)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </div>
  );
};
