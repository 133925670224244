import React from 'react';

import { makeStyles, useTheme } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';

import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

import IconButton from '@material-ui/core/IconButton';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { ActiveAgentsListCmp } from './agent-list.active.component';
import { InactiveAgentsListCmp } from './agent-list.inactive.component';

const TabPanel = ({ children, value, index, ...other }) => (
  <div>
    {value === index && (
      <Box p={3}>
        <Typography>{children}</Typography>
      </Box>
    )}
  </div>
);

const useStyles = makeStyles(theme => ({
  root: {
    width: '3%',
    position: 'fixed',
    right: '0',
    top: '0',
    height: '100%',
    overflow: 'visible',
    backgroundColor: '#212447',
    boxShadow: '0px 3px 55px #00000075',
    borderRadius: '51px 0 0 0'
  },
  items: {
    backgroundColor: 'black'
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    top: '60px',
    borderRadius: '31px 0 0 0'
  },
  scroll: {
    overflow: 'scroll'
  },
  appBarShift: {
    top: '60px',
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginRight: drawerWidth
  },
  title: {
    flexGrow: 1
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 1,
    backgroundColor: '#202447',
    boxShadow: '0px 3px 55px #00000075'
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginRight: -drawerWidth
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginRight: 0
  },

  open: {
    position: 'absolute',
    background: '#bf5dc3',
    borderRadius: '50%',
    padding: 5,
    height: 37,
    width: 37,
    left: -10,
    top: 50
  },
  close: {
    position: 'absolute',
    background: '#202447',
    borderRadius: '50%',
    padding: 5,
    height: 37,
    width: 37,
    left: -24,
    top: 16,
    zIndex: 100
  },
  drawerTitle: {
    fontSize: 40,
    fontFamily: 'Quicksand, sans-serif',
    paddingTop: 40
  },
  drawerLabel: {
    color: 'white',
    transform: 'rotate(-90deg)',
    top: 110,
    position: 'relative'
  }
}));

const drawerWidth = 350;

export const AgentTblCmp = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [selected, setSelected] = React.useState(0);
  const [open, setOpen] = React.useState(false);

  const onSelected = (event, selected) => {
    setSelected(selected);
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="end"
        onClick={handleDrawerOpen}
        className={clsx(open && classes.hide)}
      >
        <ChevronLeftIcon className={classes.open} />
      </IconButton>
      <Typography className={classes.drawerLabel}>Agents</Typography>
      {/*<Drawer*/}
      {/*  className={classes.drawer}*/}
      {/*  variant="persistent"*/}
      {/*  anchor="right"*/}
      {/*  open={open}*/}
      {/*>*/}
      {/*  <AppBar position="static" className={classes.appBar}>*/}
      {/*    <div className={classes.drawerHeader}>*/}
      {/*      <IconButton>*/}
      {/*        {theme.direction === 'rtl' ? (*/}
      {/*          <ChevronLeftIcon />*/}
      {/*        ) : (*/}
      {/*          <ChevronRightIcon*/}
      {/*            className={classes.close}*/}
      {/*            onClick={handleDrawerClose}*/}
      {/*          />*/}
      {/*        )}*/}
      {/*      </IconButton>*/}
      {/*      <p className={classes.drawerTitle}>Orchestrate</p>*/}
      {/*    </div>*/}
      {/*    <Tabs value={selected} onChange={onSelected}>*/}
      {/*      <Tab label="Active" />*/}
      {/*      /!* <Tab label="Scheduled" /> *!/*/}
      {/*      <Tab label="Inactive" />*/}
      {/*      /!* <Tab label="All" /> *!/*/}
      {/*    </Tabs>*/}
      {/*  </AppBar>*/}

      {/*  <TabPanel value={selected} index={0}>*/}
      {/*    <ActiveAgentsListCmp />*/}
      {/*  </TabPanel>*/}

      {/*  <TabPanel value={selected} index={1}>*/}
      {/*    <InactiveAgentsListCmp />*/}
      {/*  </TabPanel>*/}
      {/*</Drawer>*/}
    </div>
  );
};
