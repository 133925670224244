import React from 'react';

import { ToastContainer, toast } from 'react-toastify';

import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';

import { RandomLoader } from '../../__common/spinners/global-spinners';

import { TablePaging } from '../../__common/table/table.paging.cmp';
import { TableToolbar } from '../../__common/table/table.toolbar.cmp';
import { TableHeader } from '../../__common/table/table.header.cmp';

import { AddBotBtn } from './agent-tbl.add-bot';
import { AgentStatusCmp } from './agent-tbl.status';
import { AssignedBotsCmp } from './agent-tbl.agent-bots';
import { ActionsMainMoreMenuCmp } from './agent-tbl.more-menu';
import { AgentStatus } from './utils';

import { nexusHttpClient } from '../../../../../src/app/services/httpClients';
import { useUserContext } from '../../../hooks/useUserContext';

import { useInterval } from '../../../hooks/useInterval';

import axios from 'axios';

const sortDirection = {
  ASC: 'asc',
  DESC: 'desc'
};

const tblColumns = [
  {
    name: 'name',
    label: 'Name',
    numeric: false,
    disablePadding: true,
    align: 'left',
    sortable: true
  },
  {
    name: 'status',
    label: 'Status',
    numeric: false,
    disablePadding: true,
    align: 'left',
    sortable: true
  },

  {
    name: 'bots',
    label: 'Bots',
    numeric: false,
    disablePadding: true,
    align: 'left',
    sortable: false
  },
  {
    name: 'actions',
    label: 'Actions',
    disablePadding: true,
    align: 'left',
    sortable: false
  }
];

const descendingComparator = (item_a, item_b, orderBy) => {
  if (item_b[orderBy]?.toLowerCase() < item_a[orderBy]?.toLowerCase())
    return -1;
  if (item_b[orderBy]?.toLowerCase() > item_a[orderBy]?.toLowerCase()) return 1;
  return 0;
};

const getComparator = (order, column) =>
  order === sortDirection.DESC
    ? (item_a, item_b) => descendingComparator(item_a, item_b, column)
    : (item_a, item_b) => -descendingComparator(item_a, item_b, column);

const sortBy = (source, comparator) =>
  source
    .map((rowItem, index) => {
      return [rowItem, index];
    })
    .sort((item_1, item_2) => {
      const order = comparator(item_1[0], item_2[0]);
      return order !== 0 ? order : item_1[1] - item_2[1];
    })
    .map(el => {
      return el[0];
    });

const actionDescriptor = {
  ON_FETCHED: 'ON_FETCHED',

  ON_STATUS_CHECKED: 'ON_STATUS_CHECKED',

  ON_PAGE_CHANGE: 'ON_PAGE_CHANGE',
  ON_PAGE_SIZE_CHANGE: 'ON_PAGE_SIZE_CHANGE',

  ON_FILTER_CHANGE: 'ON_FILTER_CHANGE',

  ON_ROW_SELECT: 'ON_ROW_SELECT',
  ON_ROWS_SELECT: 'ON_ROWS_SELECT',
  ON_ROWS_UNSELECT: 'ON_ROWS_UNSELECT',

  ON_COLUMN_SORT: 'ON_COLUMN_SORT',

  ON_AGENT_STOPED: 'ON_AGENT_STOPED',
  ON_BOT_STARTED: 'ON_BOT_STARTED',

  ON_BOT_ADDED: 'ON_BOT_ADDED',

  ON_REMOVE_BOT: 'ON_REMOVE_BOT',
  ON_BOT_REMOVED: 'ON_BOT_REMOVED'
};

const initialState = {
  loading: true,

  orderColumn: {
    name: 'name',
    direction: sortDirection.ASC
  },

  filter: '',

  pageNo: 1,
  pageSize: 5,
  pageCount: 1,
  pageSizeOptions: [5, 10, 25],

  agents: [],
  count: 0,
  selected: [],

  /** state used to block multiple click events when removing */
  selectedBot: {},

  statusCode: 200,
  error: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionDescriptor.ON_FETCHED: {
      return {
        ...state,
        loading: false,
        agents: action.payload.agents,
        count: action.payload.count,
        pageCount: Math.ceil(action.payload.count / state.pageSize)
      };
    }
    case actionDescriptor.ON_STATUS_CHECKED: {
      return {
        ...state,
        agents: action.payload.agents
      };
    }
    case actionDescriptor.ON_PAGE_CHANGE: {
      return {
        ...initialState,
        pageNo: action.payload,
        filter: state.filter,
        pageSize: state.pageSize
      };
    }
    case actionDescriptor.ON_PAGE_SIZE_CHANGE: {
      return {
        ...initialState,
        pageSize: action.payload.size,
        filter: state.filter
      };
    }
    case actionDescriptor.ON_FILTER_CHANGE: {
      return {
        ...state,
        pageNo: initialState.pageNo,
        selected: initialState.selected,
        filter: action.payload
      };
    }
    case actionDescriptor.ON_ROW_SELECT: {
      return {
        ...state,
        selected: action.payload
      };
    }
    case actionDescriptor.ON_ROWS_SELECT:
    case actionDescriptor.ON_ROWS_UNSELECT: {
      return {
        ...state,
        selected: action.payload
      };
    }
    case actionDescriptor.ON_COLUMN_SORT: {
      return {
        ...state,
        orderColumn: {
          name: action.payload.name,
          direction: action.payload.direction
        }
      };
    }
    case actionDescriptor.ON_REMOVE_BOT: {
      return {
        ...state,
        selectedBot: {
          id: action.payload.botId,
          agentId: action.payload.agentId,
          guard: action.payload.guard
        }
      };
    }
    case actionDescriptor.ON_BOT_REMOVED: {
      const agent = state.agents.find(
        item => item.id === action.payload.agentId
      );
      const newBotList = agent.bots.filter(
        item => item.id !== action.payload.botId
      );
      agent.bots = newBotList;
      agent.botCount = agent.bots.length;

      return {
        ...state
      };
    }

    case actionDescriptor.ON_BOT_ADDED: {
      const agent = state.agents.find(
        agent => agent.id === action.payload.agentId
      );
      agent.bots.push({
        id: action.payload.botId,
        name: action.payload.botName,
        isRunning: false
      });
      agent.botCount = agent.bots.length;

      return {
        ...state
      };
    }

    case actionDescriptor.ON_AGENT_STOPED: {
      const agent = state.agents.find(
        item => item.id === action.payload.agentId
      );

      const projection = agent.bots.map(bot => {
        if (bot.id === action.payload.botId) {
          bot.isRunning = false;
        }
        return bot;
      });

      agent.status = AgentStatus.Idle;

      return {
        ...state
      };
    }
    case actionDescriptor.ON_BOT_STARTED: {
      const agent = state.agents.find(
        item => item.id === action.payload.agentId
      );

      agent.bots = agent.bots.map(bot => {
        if (bot.id === action.payload.botId) {
          bot.isRunning = true;
        }
        return bot;
      });

      agent.status = AgentStatus.Running;

      return {
        ...state
      };
    }
  }
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  selected: {
    color: 'red'
  },

  paper: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },

  table: {
    minWidth: 750
  },
  tableRow: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

export const AgentTblCmp = () => {
  const classes = useStyles();

  const [state, dispatch] = React.useReducer(reducer, initialState);
  const { accessToken } = useUserContext();

  useInterval(() => {
    if (state.agents.length === 0) return;

    const runningAgents = state.agents.filter(
      agent => agent.status === 'Running'
    );
    const idleAgents = state.agents.filter(agent => agent.status === 'Idle');

    if (runningAgents.length === 0) return;

    const ids = runningAgents.map(agent => agent.id).join(',');

    axios({
      method: 'GET',
      url: `${process.env.REACT_APP_TAP_NEXUS}/api/agents/check-status?ids=${ids}`,
      headers: {
        'CONTENT-TYPE': 'application/json',
        AUTHORIZATION: `Bearer ${accessToken}`
      }
    })
      .then(response => {
        runningAgents.forEach(agent => {
          agent.status =
            response.data.value.find(item => {
              return item.id === agent.id;
            }).status === 1
              ? 'Idle'
              : 'Running';

          if (agent.status === 'Idle') {
            agent.bots.forEach(bot => {
              bot.isRunning = false;
            });
          }

          return agent;
        });

        dispatch({
          type: actionDescriptor.ON_STATUS_CHECKED,
          payload: {
            agents: runningAgents.concat(idleAgents)
          }
        });
      })
      .catch(error => {});
  }, 3000);

  React.useEffect(() => {
    axios({
      method: 'GET',
      url: `${process.env.REACT_APP_TAP_NEXUS}/api/agents?skip=${(state.pageNo -
        1) *
        state.pageSize}&take=${state.pageSize}&filter=${state.filter}`,
      headers: {
        'CONTENT-TYPE': 'application/json',
        AUTHORIZATION: `Bearer ${accessToken}`
      }
    })
      .then(response => {
        dispatch({
          type: actionDescriptor.ON_FETCHED,
          payload: {
            agents: response.data.value.map(agent => {
              return {
                id: agent.id,
                name: agent.name,
                status: agent.status === 1 ? 'Idle' : 'Running',
                botCount: agent.bots.length,
                bots: agent.bots.map(bot => {
                  return {
                    id: bot.id,
                    name: bot.name,
                    isRunning: bot.isRunning
                  };
                })
              };
            }),
            count: response.data.count
          }
        });
      })
      .catch(error => {});
  }, [state.pageNo, state.pageSize, state.filter]);

  const onPageChangeHandler = (_event, page) => {
    if (state.pageNo === page) return;

    dispatch({
      type: actionDescriptor.ON_PAGE_CHANGE,
      payload: page
    });
  };

  const onPageSizeChangeHandler = event => {
    dispatch({
      type: actionDescriptor.ON_PAGE_SIZE_CHANGE,
      payload: {
        size: parseInt(event.target.value, 10)
      }
    });
  };

  const onOrderColumnHandler = (_event, property) => {
    const isAsc =
      state.orderColumn.name === property &&
      state.orderColumn.direction === sortDirection.ASC;
    dispatch({
      type: actionDescriptor.ON_COLUMN_SORT,
      payload: {
        name: property,
        direction: isAsc ? sortDirection.DESC : sortDirection.ASC
      }
    });
  };

  const onSelectHandler = (_event, id) => {
    const selectedIndex = state.selected.indexOf(id);
    let selected = [];
    if (selectedIndex === -1) {
      selected = selected.concat(state.selected, id);
    } else if (selectedIndex === 0) {
      selected = selected.concat(state.selected.slice(1));
    } else if (selectedIndex === state.selected.length - 1) {
      selected = selected.concat(state.selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      selected = selected.concat(
        state.selected.slice(0, selectedIndex),
        state.selected.slice(selectedIndex + 1)
      );
    }
    dispatch({
      type: actionDescriptor.ON_ROW_SELECT,
      payload: selected
    });
  };

  const onSelectAllHandler = event => {
    if (event.target.checked) {
      dispatch({
        type: actionDescriptor.ON_ROWS_SELECT,
        payload: state.agents.map(row => row.id)
      });
    } else {
      dispatch({
        type: actionDescriptor.ON_ROWS_UNSELECT,
        payload: []
      });
    }
  };

  const isSelected = agentId => state.selected.indexOf(agentId) !== -1;

  const onChangeFilterHandler = input => {
    dispatch({
      type: actionDescriptor.ON_FILTER_CHANGE,
      payload: input
    });
  };

  const onAddBotHandler = async (agentId, botId, botName) => {
    try {
      const response = await nexusHttpClient(accessToken).post(
        `api/agents/${agentId}/bots/associate`,
        botId
      );

      dispatch({
        type: actionDescriptor.ON_BOT_ADDED,
        payload: {
          agentId: agentId,
          botId: botId,
          botName: botName
        }
      });
    } catch (error) {
      toast.error(' Could not add bot! ', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
    }
  };

  const onStopAgentHandler = (agentId, botId) => {
    nexusHttpClient(accessToken)
      .put(`api/agents/${agentId}/cancel-bot-execution`, { botId: botId })
      .then(response => {
        dispatch({
          type: actionDescriptor.ON_AGENT_STOPED,
          payload: {
            agentId: agentId,
            botId: botId
          }
        });
      })
      .catch(error => {
        toast.warn(' Could not stop agent execution! ', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      });
  };

  const onStopBotHandler = (agentId, botId) => {
    nexusHttpClient(accessToken)
      .put(`api/agents/${agentId}/cancel-bot-execution`, { botId: botId })
      .then(response => {
        dispatch({
          type: actionDescriptor.ON_AGENT_STOPED,
          payload: {
            agentId: agentId,
            botId: botId
          }
        });
      })
      .catch(error => {
        toast.warn(' Bot could not be stoped! ', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      });
  };

  const onStartBotHandler = (agentId, botId) => {
    nexusHttpClient(accessToken)
      .put(`api/agents/${agentId}/start-bot`, { botId: botId })
      .then(response => {
        dispatch({
          type: actionDescriptor.ON_BOT_STARTED,
          payload: {
            agentId: agentId,
            botId: botId
          }
        });
      })
      .catch(error => {
        /// https://github.com/fkhadra/react-toastify/issues/423
        ///
        toast.warn(' Bot could not be started! ', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      });
  };

  const onRemoveBotHandler = async (agentId, botId) => {
    dispatch({
      type: actionDescriptor.ON_REMOVE_BOT,
      payload: {
        botId: botId,
        agentId: agentId,
        guard: true
      }
    });

    try {
      await nexusHttpClient(accessToken).delete(
        `api/agents/${agentId}/bots/${botId}/dissociate`
      );

      dispatch({
        type: actionDescriptor.ON_BOT_REMOVED,
        payload: {
          agentId: agentId,
          botId: botId
        }
      });
    } catch (error) {
      toast.error(' Could not remove bot! ', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
    }
  };

  return (
    <>
      {state.loading ? (
        <RandomLoader />
      ) : (
        <>
          <ToastContainer />
          <Typography className="pageTitle">Agents</Typography>
          <div>
            <Paper className={'table'}>
              <TableToolbar
                selected={state.selected.length}
                defaultValue={state.filter}
                onChangeFilter={onChangeFilterHandler}
              />

              <TableContainer>
                <Table
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  size="medium"
                  aria-label="enhanced table"
                >
                  <TableHeader
                    classes={classes}
                    columns={tblColumns}
                    selected={state.selected.length}
                    order={state.orderColumn.direction}
                    orderBy={state.orderColumn.name}
                    onSelectAll={onSelectAllHandler}
                    onRequestSort={onOrderColumnHandler}
                    rowCount={state.agents.length}
                    style={{ display: 'flex' }}
                  />

                  <TableBody>
                    {sortBy(
                      state.agents,
                      getComparator(
                        state.orderColumn.direction,
                        state.orderColumn.name
                      )
                    ).map(agent => {
                      const isItemSelected = isSelected(agent.id);
                      return (
                        <TableRow
                          key={agent.id}
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          selected={isItemSelected}
                        >
                          {/* <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            onClick={event => onSelectHandler(event, agent.id)}
                          />
                        </TableCell> */}

                          <TableCell
                            component="th"
                            scope="row"
                            padding="default"
                            align="inherit"
                            style={{ fontSize: '16px', height: 100 }}
                          >
                            <Typography>{agent.name}</Typography>
                          </TableCell>

                          {/* <TableCell
                          component="th"
                          scope="row"
                          padding="default"
                          align="left"
                        >
                          {agent.assignedName}
                        </TableCell> */}

                          <TableCell
                            component="th"
                            scope="row"
                            padding="default"
                            align="left"
                          >
                            <AgentStatusCmp
                              status={agent.status}
                              runningBotName={
                                agent.bots.find(bot => bot.isRunning)?.name
                              }
                            />
                          </TableCell>

                          <TableCell align="left">
                            <AddBotBtn
                              agent={agent}
                              onAddBot={onAddBotHandler}
                              onRemoveBot={onRemoveBotHandler}
                            />
                            <AssignedBotsCmp
                              agent={agent}
                              selectedBot={state.selectedBot}
                              onRemoveBot={onRemoveBotHandler}
                            />
                          </TableCell>

                          <TableCell align="left">
                            <ActionsMainMoreMenuCmp
                              key={agent.id}
                              agent={agent}
                              disabled={agent.bots.length === 0}
                              onStopAgent={onStopAgentHandler}
                              onStopBot={onStopBotHandler}
                              onStartBot={onStartBotHandler}
                              onRemoveBot={onRemoveBotHandler}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>

                <TablePagination
                  component="div"
                  count={state.count}
                  rowsPerPage={state.pageSize}
                  rowsPerPageOptions={state.pageSizeOptions}
                  page={state.pageNo - 1}
                  labelDisplayedRows={({ from, to, count }) =>
                    `Showing ${from} to ${to} of ${count}`
                  }
                  onChangeRowsPerPage={onPageSizeChangeHandler}
                  nextIconButtonProps={{ style: { visibility: 'hidden' } }}
                  backIconButtonProps={{ style: { visibility: 'hidden' } }}
                  onChangePage={(_e, _page) => {}}
                />

                <TablePaging
                  pageNo={state.pageNo}
                  pageCount={state.pageCount}
                  onChange={onPageChangeHandler}
                  linkTo={'/control-center/agents'}
                />
              </TableContainer>
            </Paper>
          </div>
        </>
      )}
    </>
  );
};
