import {createStyles, makeStyles} from '@material-ui/core';

import {theme} from './theme/theme-builder';

const useStyles = makeStyles(() =>
    createStyles({
        '@global': {
            '*': {
                boxSizing: 'border-box',
                margin: 0,
                padding: 0,
                fontFamily: 'Quicksand, sans-serif'
            },
            html: {
                '-webkit-font-smoothing': 'antialiased',
                '-moz-osx-font-smoothing': 'grayscale',
                height: '100%',
                width: '100%'
            },
            body: {
                height: '100%',
                width: '100%'
                //width: '1920px'
            },
            '*::-webkit-scrollbar': {
                width: '0.4em'
            },
            '*::-webkit-scrollbar-track': {
                '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
            },
            '*::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(0, 6, 84,.7)',
                outline: '1px solid slategrey'
            },
            a: {
                textDecoration: 'none'
            },
            '#root': {
                height: '100%',
                width: '100%',
                background: '#050A31'
            },
            '.logo': {
                width: 100
            },
            '.btn': {
                backgroundColor: '#C05EC3',
                width: 150,
                height: 38,
                borderRadius: 25,
                fontWeight: '600',
                '&:hover': {
                    backgroundColor: '#994a9a'
                }
            },
            '.MuiOutlinedInput-input': {
                background: '#212447',
                borderRadius: 30
            },
            '.MuiDialog-paperScrollPaper': {
                backgroundColor: '#050a31'
            },

            '.outlineBtn': {
                color: '#fff',
                backgroundColor: 'transparent',
                fontWeight: '600',
                borderRadius: '32px',
                border: '1px solid #fff'
            },
            '.card': {
                backgroundColor: '#050A31',
                borderRadius: '9px'
            },
            '.input': {
                borderRadius: 30,
                color: '#fff',
                height: 60
            },

            '.navbar': {
                backgroundColor: theme.palette.primary.main,
                justifyContent: 'space-between'
            },

            '.active': {
                color: '#fff',
                borderLeft: '7px solid #C05EC3',
                borderRadius: '0',
                paddingLeft: '22px !important'
            },
            '.table': {
                backgroundColor: theme.palette.primary.main,
                width: '95%',
                margin: '4rem auto 2rem',
                borderRadius: '9px'
            },
            '.pagination': {
                color: 'white'
            },
            '.pageTitle': {
                color: '#fff',
                fontSize: 32,
                position: 'relative',
                top: 30,
                width: '95%',
                margin: 'auto'
            },
            '.MuiTableCell-root': {
                borderBottom: '1px solid rgb(33 36 71)'
            },
            '.MuiInputLabel-outlined.MuiInputLabel-shrink': {
                transform: 'translate(14px, -5px) scale(0.6)',
                fontWeight: 600
            },
            '.MuiButton-label': {
                fontSize: 16
            },
            '.botImg': {
                width: '32px',
                height: '31px',
                marginRight: '10px',
                verticalAlign: 'middle'
            },
            '.botImg-small': {
                width: '20px',
                height: '20px',
                verticalAlign: 'middle'
            },
            '.botImg-large': {
                width: '60px',
                height: '60px',
                verticalAlign: 'middle',
                marginRigh: 10
            },
            '.botNo': {
                fontSize: 60,
                color: '#fff'
            },
            '.MuiSelect-icon': {
                color: 'white'
            },
            '.aside-item': {
                backgroundColor: '#050A31',
                padding: '6px 15px',
                margin: '10px auto 0',
                boxShadow: '0px 3px 6px #00000029',
                borderRadius: 7,
                height: 44,
                width: 300,
                display: 'flex',
                alignItems: 'center'
            },

            'button:disabled': {
                opacity: '0.15'
            },

            '.MuiIconButton-label': {
                color: '#fff'
            },
            '.MuiTab-textColorInherit.Mui-selected': {
                borderBottom: '5px solid #C05EC3'
            },
            '.MuiDialog-paperWidthSm': {
                width: 'fit-content'
            },
            '.tbrow:hover': {
                backgroundColor: '#21244778'
            },
            '.makeStyles-root-33': {
                maxWidth: 'initial !important'
            },
            '.MuiDrawer-paperAnchorDockedRight': {
                border: 'none',
                backgroundColor: '#202447',
                boxShadow: '0px 3px 55px #00000075',
                overflow: 'visible',
                width: 350,
                borderRadius: '31px 0 0 0'
            },

            '.MuiPaginationItem-textPrimary.Mui-selected': {
                backgroundColor: '#bf5cc3'
            },
            '.MuiInputBase-root.Mui-disabled': {
                marginTop: -16,
                height: 60,
                color: '#fff',
                opacity: '.6'
            },
            '.Mui-disabled': {
                opacity: '.6'
            },
            '.MuiOutlinedInput-notchedOutline': {
                border: 'none'
            },
            //  DASHBOARD
            '.panels': {
                display: 'flex',
                justifyContent: 'space-between',
                margin: 'auto',
                width: '90%'
            },
            '.panels > div': {
                backgroundColor: '#050A31',
                width: '22%',
                height: 200,
                borderRadius: 10
            },
            '.botImgContainer ': {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            },
            '.botsBtn': {
                background: 'transparent',
                border: 'none',
                outline: 'none',
                fontSize: 20,
                margin: 'auto',
                color: '#fff',
                display: 'flex',
                alignItems: 'center'
            },
            '.makeStyles-root-1': {
                background: 'transparent'
            },
            '.runningContainer': {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 20
            },
            '.executedInfo': {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            },
            '.viewMore': {
                width: 600
            },
            '.scheduler': {
                backgroundColor: 'red',
            },
            '.color-calendar.glass .calendar__body': {
                backgroundColor: '#050A31',

            },
            '.color-calendar.glass.color-calendar--small': {
                color: 'white',
            },
            '.color-calendar .calendar__picker .calendar__picker-year-option': {
                color: 'white',
            },
            '.color-calendar.glass .calendar__picker': {
                backgroundColor: '#050A31',
            },
            '.events-display': {
                width: 307,
                borderRadius: '0 0 9px 9px',
                backgroundColor: '#050A31',
                color: '#050a31',
            },
            '.events-display .event': {
                padding: 5,
            },
            '.events-display .event .event-left .event-title': {
                fontSize: 16,
                textTransform: 'uppercase',
                marginBottom: 0,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                color: 'white',
                border: '1px solid #222447',
                padding: 5,
                borderRadius: 3,
            },
            '.events-display .event .event-left .event-title #expand, .bot-container #expand': {
                backgroundColor: '#C05EC3',
                width: 20,
                height: 20,
                display: 'flex',
                borderRadius: '50%',
                color: 'white',
                fontWeight: 600,
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer'
            },
            '.events-display .event .event-left .event-title #expand:hover': {
                background: '#974c9a',
            },
            '.events-display .event .event-left .event-title .expand p': {
                marginBottom: 5,
                cursor: 'pointer'
            },
            '.events-display select, .events-display input': {
                fontSize: 16,
                backgroundColor: '#050A31',
                border: 'none',
                borderBottom: '1px solid #202447',
            },
            '.events-display .event #botsSelect': {
                margin: '5px auto',
                width: '100%',
                marginBottom: 20

            },
            '.events-display .event #selectDatetime': {
                margin: '5px auto',
                width: '60%',
                fontSize: 14

            },
            '.events-display .event #selectDatetime::-webkit-inner-spin-button, .events-display .event #selectDatetime::-webkit-calendar-picker-indicator, .events-display .event #selectDatetimeBot::-webkit-inner-spin-button, .events-display .event #selectDatetimeBot::-webkit-calendar-picker-indicator': {
                // opacity: 0
                color: '#fff !important',
                filter: 'invert(1)',
                cursor: 'pointer',
            },
            '.events-display .event #recuringScheduling': {
                margin: '5px auto',
                width: '30%',
                float: 'right'
            },
            '.events-display .event #scheduleBtn, #scheduleBtnBot': {
                background: 'transparent linear-gradient(90deg, #B063A7 0%, #B063A7 60%, #5A78BA 100%) 0% 0% no-repeat padding-box',
                borderRadius: 18,
                padding: '5px 20px',
                marginTop: 20,
            },
            '.events-display .event #cancelSchedule,#cancelSchedule2': {
                background: 'transparent',
                border: 'none',
                outline: 'none',
                fontSize: 16,
                fontWeight: 600,
                color: 'white',
                opacity: .3,
                marginLeft: 30,
                display: 'inline-block',
                cursor: 'pointer'
            },
            '.events-display .event label': {
                fontSize: 12,
                color: '#fff',
                opacity: .5,
                width: '100%',
                "max-height": "200px"
            },
            '#scheduleFormBot label': {
                fontSize: 12,
                color: '#fff',
                opacity: .5,
                width: '100%'
            },
            '.e-schedule .e-vertical-view .e-left-indent-wrap table tbody td.e-header-cells, .e-schedule .e-vertical-view .e-date-header-wrap table tbody td.e-header-cells, .e-schedule .e-vertical-view .e-date-header-wrap table tbody td:first-child, .e-schedule .e-vertical-view .e-content-wrap table td:first-child, .e-schedule .e-month-view .e-date-header-wrap table td:first-child, .e-schedule .e-month-view .e-content-wrap table td:first-child, .e-schedule .e-vertical-view .e-left-indent-wrap table tbody td, .e-schedule .e-vertical-view .e-date-header-wrap table tbody td, .e-schedule .e-vertical-view .e-time-cells-wrap table td, .e-schedule .e-vertical-view .e-work-cells, .e-schedule .e-month-view .e-date-header-wrap table td, .e-schedule .e-month-agenda-view .e-date-header-wrap table td, .e-schedule .e-month-view .e-work-cells, .e-schedule .e-month-agenda-view .e-work-cells': {
                backgroundColor: '#050A31',
            },
            '.e-schedule .e-outer-table>tbody>tr>td, .e-schedule .e-schedule-toolbar, .e-schedule .e-vertical-view .e-left-indent-wrap table tbody td.e-header-cells, .e-schedule .e-vertical-view .e-date-header-wrap table tbody td.e-header-cells, .e-schedule .e-vertical-view .e-date-header-wrap table tbody td:first-child, .e-schedule .e-vertical-view .e-content-wrap table td:first-child, .e-schedule .e-month-view .e-date-header-wrap table td:first-child, .e-schedule .e-month-view .e-content-wrap table td:first-child, .e-schedule .e-vertical-view .e-left-indent-wrap table tbody td, .e-schedule .e-vertical-view .e-date-header-wrap table tbody td, .e-schedule .e-vertical-view .e-time-cells-wrap table td, .e-schedule .e-vertical-view .e-work-cells, .e-schedule .e-month-view .e-date-header-wrap table td, .e-schedule .e-month-agenda-view .e-date-header-wrap table td, .e-schedule .e-month-view .e-work-cells, .e-schedule .e-month-agenda-view .e-work-cells': {
                border: 'none'
            },
            '.e-schedule .e-schedule-table': {
                borderBottom: '1px solid #202447'
            },
            '.e-control, .property-section': {
                background: 'transparent',
            },
            '.e-schedule .e-table-container': {
                borderRadius: 10,
                overflow: 'hidden'
            },
            '.color-calendar.glass .calendar__days .calendar__day-bullet': {
                top: 25,
            },
            '.bot--list-item': {
                borderRadius: 6,
                border: '1px solid #202447',
                padding: 10
            },
            '.open-accordion': {
                height: '220px !important',
                overflow: 'hidden',
                transition: 'height 1s',
            },
            '.open-accordion2': {
                height: '220px !important',
                overflow: 'hidden',
                transition: 'height 1s',
                border: '1px solid #202447',
                borderRadius: '0 0 3px 3px',
                borderTop: 'none'

            },
            '.event': {
                "max-height": "100px"
            },
            '.event.hide-event': {
                'max-height': '0px',
                overflow: 'hidden',
                transition: "max-height 1s",
                padding: '0px',
                transition: "padding 0.9s"
                
            },
            '.hide-element, .botList-container .hide-element': {
                transition: 'height 1s',
                overflow: 'hidden',
                height: 0,
                position: 'relative'
            },
            '.event-left .show-details': {
                height: '190px !important',
                transition: 'height 1s',
                overflow: 'hidden'
            },
            '.event-left .search-form': {
                height: 190,
                transition: 'height 1s',
                overflow: 'hidden'
            },
            '#cancelSchedule': {
                display: 'inline-block',
                cursor: 'pointer'
            },
            '.bot-container': {
                marginLeft: 10,
                border: '1px solid #222447',
                padding: '7px 5px',
                borderRadius: 3,
                cursor: 'pointer',
                color: '#fff'
            },
            '.bot-container:hover': {
                background: '#222447',
                borderColor: '#404154',
            },
            '.botList-container .hide-element': {
                marginLeft: 10,
                padding: '0 5px',
                position: 'relative'

            },
            '.botList-container .open-accordion2': {
                paddingTop: 10,
                color: '#fff',
            },
            '.noBorder': {
                borderBottom: 'none',
            },
            '.e-schedule .e-vertical-view .e-header-cells.e-current-day,.e-schedule .e-vertical-view .e-clone-time-indicator, .e-schedule .e-vertical-view .e-current-time': {
                color: '#bf5cc3'
            },
            '.e-schedule .e-vertical-view .e-previous-timeline': {
                borderTop: '1px dotted #bf5cc3'
            },
            '.e-schedule .e-vertical-view .e-current-timeline': {
                borderColor: '#bf5cc3'
            },
            '.e-calendar .e-content td.e-today.e-selected span.e-day, .e-bigger.e-small .e-calendar .e-content td.e-today.e-selected span.e-day, .e-calendar .e-content td.e-today:hover span.e-day, .e-calendar .e-content td.e-focused-date.e-today:hover span.e-day, .e-calendar .e-content td.e-focused-date.e-today:focus span.e-day, .e-bigger.e-small .e-calendar .e-content td.e-today:hover span.e-day, .e-bigger.e-small .e-calendar .e-content td.e-focused-date.e-today:hover span.e-day, .e-bigger.e-small .e-calendar .e-content td.e-focused-date.e-today:focus span.e-day': {
                border: '1px solid #bf5cc3'
            },
            '.e-calendar .e-content.e-year td.e-selected:hover span.e-day, .e-calendar .e-content.e-decade td.e-selected:hover span.e-day, .e-bigger.e-small .e-calendar .e-content.e-year td.e-selected:hover span.e-day, .e-bigger.e-small .e-calendar .e-content.e-decade td.e-selected:hover span.e-day': {
                backgroundColor: '#bf5cc3',
            },
            '.e-calendar .e-content td.e-focused-date:hover span.e-day, .e-bigger.e-small .e-calendar .e-content td.e-focused-date:hover span.e-day': {
                backgroundColor: '#753779'
            },
            '.e-btn.e-flat.e-primary:hover, .e-css.e-btn.e-flat.e-primary:hover': {
                backgroundColor: '#753779',
                borderColor: '#753779'
            },
            '.e-btn.e-flat.e-primary:active, .e-btn.e-flat.e-primary.e-active, .e-css.e-btn.e-flat.e-primary:active, .e-css.e-btn.e-flat.e-primary.e-active': {
                backgroundColor: '#753779',
            },
            '.e-schedule .e-month-view .e-current-date .e-date-header, .e-schedule .e-month-agenda-view .e-current-date .e-date-header': {
                color: '#bf5cc3'
            },
            '.e-schedule .e-month-view .e-appointment .e-appointment-details .e-subject': {
                wordBreak: 'break-all'
            },
            '.e-schedule .e-schedule-toolbar .e-toolbar-items .e-toolbar-left .e-tbar-btn.e-icon-btn:focus, .e-schedule .e-schedule-toolbar .e-toolbar-items .e-toolbar-left .e-tbar-btn.e-icon-btn:hover': {
                borderRadius: '50%'
            },
            '.no-events-text': {
                padding: 5
            },
            '.botList-container': {
                color: '#050a31',
            },

            'input[type="datetime-local"]::-webkit-calendar-picker-indicator': {
                filter: 'invert(1)'
            },
            'input[type="datetime-local"]': {
                fontSize: 12
            },
            '#selectDatetimeBot': {
                width: '55%',
                fontSize: 12
            },
            '.bot-calendar':{
                width: '100%',
                fontSize: 12
            },
            '#recuringSchedulingBot': {
                float: 'right'
            },
            '.reccuring':{
                float: 'left',
                marginTop: '20px',
                marginRight: '1%',
                width: '49%'
            },
            '#unscheduleMsg-container, #unscheduleBotMsg-container': {
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                background: '#050a31',
                zIndex: 10,
                fontSize: 15,
                textTransform: 'uppercase',
                transition: '1s',
                display: 'none',
            },
            '#scheduleMsg-container, #scheduleBotMsg-container': {
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                background: '#050a31',
                zIndex: 10,
                fontSize: 15,
                textTransform: 'uppercase',
                transition: '1s',
                display: 'none',
            },
            '#schedule-msg, #scheduleBot-msg': {
                textAlign: 'center',
                position: 'relative',
                top: '50%',
                transform: 'translateY(-50%)'
            },
            '.red-warning': {
                color: 'red',
                fontWeight: 600
            },
            '.allBots .hide-element': {
                padding: '0 15px',
                color: 'white'
            },
            '.allBots .bot-container': {
                margin: '0 10px'
            },
            '.allBots-title':{
                marginTop: 20,
                color: '#fff',
                textAlign: 'center',
                fontWeight: 600,
                width: 307
            },
            '.allBots': {
                borderRadius: 9,
                padding: '15px 0'
            }
        }
    })
);

export const GlobalStyles = () => {
    useStyles();

    return null;
};
