/* eslint-disable */
import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import { ActionsMenuDialogCmp } from './bot-tbl.more-menu.v-dialog';
import { useUserContext } from '../../../hooks/useUserContext';

export const ActionsMainMoreMenuCmp = ({
  bot,
  disabled,
  onStopBot,
  onStartBot,
  onRemoveAgent,
  onRemovedBot,
  callback,
  ...rest
}) => {
  const [reload, setReload] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const {accessToken} = useUserContext();

  const menuActions = [
    {
      id: 1,
      label: 'View Details',
      disabled: bot.agents.length === 0,
      onClickHandler: () => {
        setAnchorEl(null);
        setOpen(true);
      }
    },
    {
      id: 2,
      label: 'Unpublish Bot',
      disabled: false,
      onClickHandler: () => {

        var dialogResult = window.confirm("WARNING: Unpublishing a bot will also remove any bot-agent bindings as well as remove any scheduled jobs for this bot. Are you sure?");
        
        if (dialogResult == false)
          return;

        let api = process.env.REACT_APP_TAP_NEXUS + `/api/bots/unpublish?id=${bot.id}`
        
        fetch(api, {
          method: 'get',
          async: 'true',
          headers: {
            'Content-type': 'application/json',
            AUTHORIZATION: `Bearer ${accessToken}`
          }
        }).then(function (response) {
            if (response.status == 200) {
              callback(true);
              response.text().then(x => {
                console.log(x);
              });
            }
            //window.location.reload();
          })
        .catch(function (error) {
          console.log('Something went wrong.', error);
        });
        setAnchorEl(null);
      }
    }
    
  ];
  
  return (
    <div>
    <ActionsMenuDialogCmp
    bot={bot}
    open={open}
    onClose={() => setOpen(false)}
    onStop={onStopBot}
    onStart={onStartBot}
    onRemoveAgent={onRemoveAgent}
    />
    
    {/* VERTICAL MENU */}
    <IconButton
    aria-label="more"
    aria-controls="long-menu"
    aria-haspopup="true"
    onClick={event => {
      setAnchorEl(event.currentTarget);
    }}
    disabled={disabled}
    >
    <MoreVertIcon />
    </IconButton>
    
    {/* VERTICAL MENU ACTIONS */}
    <Menu
    id="long-menu"
    anchorEl={anchorEl}
    keepMounted
    open={Boolean(anchorEl)}
    onClose={() => setAnchorEl(null)}
    PaperProps={{
      style: {
        width: '15ch'
      }
    }}
    >
    {menuActions.map(action => (
      <MenuItem
      key={action.id}
      value={action.label}
      disabled={action.disabled}
      onClick={() =>
        action.onClickHandler(
          bot.id,
          bot.agents.find(agent => agent.isRunning)?.id
          )
        }
        >
        {action.label}
        </MenuItem>
        ))}
        </Menu>
        </div>
        );
      };
