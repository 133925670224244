import React from 'react';
import clsx from 'clsx';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import { makeStyles } from '@material-ui/core';

import { licensingHttpClient } from '../../../services/httpClients';
import { useUserContext } from '../../../hooks/useUserContext';

const useStyles = makeStyles(() => ({
  root: {}
}));

export const ProfileDetails = ({ className, ...rest }) => {
  const classes = useStyles();

  const { accessToken, id } = useUserContext();

  const [values, setValues] = React.useState({
    name: '',
    email: '',
    organizationName: '',
    organizationEmail: ''
  });

  React.useEffect(() => {
    licensingHttpClient(accessToken)
      .get(`api/accounts/${id}`)
      .then(response => {
        setValues(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  return (
    <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card className={'card'} style={{ height: '440px' }}>
        <CardHeader
          // subheader="The information can not be edited"
          title="Profile"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <TextField
                inputProps={{
                  style: {fontSize: 16}
                }}
                InputLabelProps={{
                  style: { color: '#fff', top: '12px', fontWeight: '600' }
                }}
                className={'input'}
                fullWidth
                // helperText="Please specify the first name"
                label="Your Name:"
                name="name"
                onChange={handleChange}
                disabled
                value={values.name}
                variant="outlined"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                label="Organization Name:"
                inputProps={{
                  style: {fontSize: 16}
                }}
                InputLabelProps={{
                  style: { color: '#fff', top: '12px', fontWeight: '600' }
                }}
                className={'input'}
                name="organizationName"
                onChange={handleChange}
                disabled
                value={values.organizationName}
                variant="outlined"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                label="Your Email:"
                inputProps={{
                  style: {fontSize: 16}
                }}
                InputLabelProps={{
                  style: { color: '#fff', top: '12px', fontWeight: '600' }
                }}
                className={'input'}
                name="email"
                onChange={handleChange}
                disabled
                value={values.email}
                variant="outlined"
              />
            </Grid>
            {/* <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Phone Number"
                name="phone"
                onChange={handleChange}
                type="number"
                value={values.phone}
                variant="outlined"
              />
            </Grid> */}
            <Grid item md={12} xs={12}>
              <TextField
                className={'input'}
                fullWidth
                label="Organization Email:"
                inputProps={{
                  style: {fontSize: 16}
                }}
                InputLabelProps={{
                  style: { color: '#fff', top: '12px', fontWeight: '600' }
                }}
                name="organizationEmail"
                onChange={handleChange}
                disabled
                value={values.organizationEmail}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        {/* <Box display="flex" justifyContent="flex-end" p={2}>
          <Button
            color="primary"
            variant="contained"
            onClick={handleAccountUpdate}
          >
            Save details
          </Button>
        </Box> */}
      </Card>
    </form>
  );
};
