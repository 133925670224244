import { createMuiTheme, colors } from '@material-ui/core';

import shadows from './shadows';
import '../../app/components/__common/companyScheduler/companySheduler.css';

export const theme = createMuiTheme({
  palette: {
    background: {
      dark: '#202447',
      default: '#050A31',
      paper: colors.common.white
    },
    primary: {
      main: '#050A31'
    },
    secondary: {
      main: '#060607'
    },
    text: {
      primary: colors.common.white,
      secondary: colors.common.white
    }
  },

  typography: {
    fontFamily: ['Quicksand', 'Roboto', 'sans-serif'].join(',')
  },
  shadows
});
