import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import { ActionsMenuDialogCmp } from './agent-tbl.more-menu.v-dialog';

export const ActionsMainMoreMenuCmp = ({
  agent,
  disabled,
  onStopAgent,
  onStopBot,
  onStartBot,
  onRemoveBot,
  ...rest
}) => {
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const menuActions = [
    {
      id: 1,
      label: 'View Details',
      disabled: false,
      onClickHandler: () => {
        setAnchorEl(null);
        setOpen(true);
      }
    },
    {
      id: 2,
      label: 'Stop',
      disabled: !agent.bots.some(bot => bot.isRunning),
      onClickHandler: (agentId, botId) => {
        onStopAgent(agentId, botId);
        setAnchorEl(null);
      }
    }
  ];

  return (
    <div >
      <ActionsMenuDialogCmp
        open={open}
        agent={agent}
        onClose={() => setOpen(false)}
        onStop={onStopBot}
        onStart={onStartBot}
        onRemove={onRemoveBot}
      />

      {/* VERTICAL MENU */}
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={event => {
          setAnchorEl(event.currentTarget);
        }}
        disabled={disabled}
      >
        <MoreVertIcon />
      </IconButton>

      {/* VERTICAL MENU ACTIONS */}
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          style: {
            width: '15ch'
          }
        }}
      >
        {menuActions.map(action => (
          <MenuItem
            key={action.id}
            value={action.label}
            disabled={action.disabled}
            onClick={() =>
              action.onClickHandler(
                agent.id,
                agent.bots.find(bot => bot.isRunning)?.id
              )
            }
          >
            {action.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
