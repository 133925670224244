import React from "react";
import Calendar from "color-calendar";
import "color-calendar/dist/css/theme-glass.css";
import { ToastContainer, toast } from 'react-toastify';
import {useUserContext} from '../../../hooks/useUserContext';
import $ from 'jquery';
import { uniq } from "lodash";

function CalendarComponent() {
    const {accessToken} = useUserContext();
    const api = process.env.REACT_APP_TAP_NEXUS + `/api/agents?skip=0&take=5&filter=`
    
    $.ajax({
        url: api,
        async: true,
        type: 'get',
        dataType: 'json',
        headers: {
            'CONTENT-TYPE': 'application/json',
            AUTHORIZATION: `Bearer ${accessToken}`
        },
        success: function (response) { 
            $.each(response.value, function (index, value) {
                const info = response.value;
                const newOp = info.map(function (bots){
                    let obj =[]
                    //console.dir(bots);
                    let getItem = bots.bots.map(function (item){
                        let elements = item.schedules.map(function (el){
                            let element ={}
                            element['id'] = el.id;
                            element['agentId'] = bots.id;
                            element['name'] = item.name;
                            element['start'] = el.initialExecution;
                            element['end'] = el.executionEstimatedEnd;
                            return element
                        })
                        return elements
                    })
                    getItem.forEach(element => element.forEach(item => obj.push(item)))
                    return obj
                })
                
                let agents = [];
                response.value.forEach(agent => {
                    agents.push({id: agent.id, name:agent.name});
                });

                let eve = [];
                response.value.forEach(resp => {
                    resp.bots.forEach(bot => {
                        bot.schedules.forEach(schedule => {
                            if (eve.filter(item => item.id === schedule.id).length == 0)
                            {
                                let sch = {}; 
                                sch['id'] = schedule.id;
                                sch['agentId'] = schedule.agentId;
                                sch['name'] = bot.name;
                                sch['start'] = schedule.initialExecution;
                                sch['end'] = schedule.executionEstimatedEnd;
                                sch['agentName'] = agents.find(x => x.id == schedule.agentId).name;
                                eve.push(sch);
                            }
                        });
                    });
                });
                
                //console.dir(response.value);
                //console.dir(agents);

                const events_display2 = document.querySelector('.allBots');
                
                var uniqueBots = [];
                
                info.forEach(element => {
                    element.bots.forEach(bot => {
                        //if bot does not exist
                        if (uniqueBots.filter(e => e.id === bot.id).length == 0)
                        {
                            uniqueBots.push(bot);
                        }
                    });
                });
                
                let events_html2 = '';
                const bolistOut = uniqueBots.map(function(el){
                    const bot = {}
                    //console.log(el.name);
                    bot['botName'] = el.name
                    bot['botId'] = el.id
                    
                    return `
                        <div class="bot-container" id="scheduleBotName` + bot.botId + `" data-id="` + bot.botId + `"> ` + bot.botName + ` </div>
                        <div class="hide-element">
                                    <div id="scheduleBotMsg-container` + bot.botId + `">
                                    <p id="scheduleBot-msg` + bot.botId + `"></p>
                                    </div>
                                    <form class="bot-form" id="scheduleFormBot` + bot.botId + `">
                                        <label style="width: 90%" for="selectDatetime">Scheduled time</label>
                                            <input type="datetime-local" class="bot-calendar" id="selectDatetimeBot` + bot.botId + `" required>
                                            <select name="recuring" class="reccuring" id="recuringSchedulingBot` + bot.botId + `">
                                                <option value="1" selected>Once</option>
                                                <option value="2">Hourly</option>
                                                <option value="3">Daily</option>
                                                <option value="4">Weekly</option>
                                                <option value="5">Monthly</option>
                                                <option value="6">Yearly</option>
                                            </select>
                                            <select name="agents" class="reccuring" id="agentsFor` + bot.botId + `">
                                            </select>

                                            <input type="submit" value="Schedule" id="scheduleBtnBot" onsubmit="event.preventDefault()" >
                                            <div id="cancelSchedule2">Cancel</div>
                                    </form>
                        </div>`
                });
                events_html2 += bolistOut
                events_display2.innerHTML = events_html2
                
                $(document).off('click').on("click", ".bot-container", function (ev) {
                    let hideEl = this.nextElementSibling;
                    hideEl.classList.toggle('open-accordion2');
                    setTimeout(this.classList.toggle('noBorder'), 2000)
                });

                document.querySelector('#cancelSchedule2').addEventListener('click', function () {

                    let hideElements = this.parentNode.parentNode;

                    hideElements.classList.remove('open-accordion2');
                });

                for(const schedule of info){
                    for(const bot of schedule.bots){

                        var select = document.getElementById("agentsFor" + bot.id);
                        if (select != null)
                        {
                            //clear child nodes
                            select.innerHTML = '';
                        }

                        const values = ["Opt1", "Opt2"];
                        for (const assignedToAgent of bot.assignedTo)
                        {
                            var option = document.createElement("option");
                            option.value = assignedToAgent.agentId;
                            option.text = assignedToAgent.agentName;
                            select.appendChild(option);
                        }

                        const scheduleFormBot = document.getElementById('scheduleFormBot' + bot.id);
                        const scheduleBotIdBot = $('#scheduleBotName' + bot.id).attr('data-id');
                        const rec =  $('#recuringSchedulingBot' + bot.id).value;
                        
                        const scheduleBotMsgContainer = $('#scheduleBotMsg-container' + bot.id);
                        const scheduleBotMsg = $('#scheduleBot-msg' + bot.id);

                      
                        $(document).off('submit', "#scheduleFormBot" + bot.id).on('submit', "#scheduleFormBot" + bot.id, function (ev) {
                            ev.preventDefault();
                            const agentOption = $('#agentsFor' + bot.id).val();
                            const scheduleDateTimeBot = $('#selectDatetimeBot' + bot.id).val(); 
                            const scheduleDateBot = scheduleDateTimeBot.substring(0, scheduleDateTimeBot.indexOf("T"))
                            const scheduleTimeBot = scheduleDateTimeBot.substring(scheduleDateTimeBot.indexOf("T") + 1)
                            const recurrenceBot =  document.querySelector('#recuringSchedulingBot' + bot.id).value;
                            console.log("Called submit for agent " + agentOption + " with frequency: " + recurrenceBot);

                            fetch(process.env.REACT_APP_TAP_NEXUS + '/api/agents/' + agentOption + '/schedule', {
                                method: 'post',
                                async: 'true',
                                body:
                                    JSON.stringify({
                                        BotId: scheduleBotIdBot,
                                        Date: scheduleTimeBot,
                                        Time: scheduleDateBot,
                                        RecurrencePattern: recurrenceBot
                                    }),
                                headers: {
                                    'Content-type': 'application/json',
                                    AUTHORIZATION: `Bearer ${accessToken}`
                                }
                            }).then(function (response) {
                                scheduleBotMsgContainer.fadeIn(500).delay(2000).fadeOut(500);
                                scheduleBotMsg.text('bot was scheduled successfully')
                                setTimeout(() => {
                                    window.location.reload();
                                }, 500)
    
                            })
    
                                .catch(function (error) {
                                console.log('Something went wrong.', error);
                                scheduleBotMsgContainer.fadeIn(500).delay(2000).fadeOut(500);
                                scheduleBotMsg.text('something went wrong. please try again')
                                scheduleBotMsg.addClass('red-warning')
    
                                });
                        })

                        // scheduleFormBot.addEventListener('submit', function(e) {
                        //     e.stopPropagation();
                        //     e.preventDefault();
                        //     console.log("haha");
                        // }); 
                                
                             
                    }
                }


                let mycal = new Calendar(
                        {
                            id: "#myCal",
                            theme: "glass",
                            weekdayType: "long-upper",
                            monthDisplayType: "long",
                            calendarSize: "small",
                            primaryColor: "#C05EC3",
                            headerBackgroundColor: '#050A31',
                            backgroundColor: '#050A31',
                            dropShadow: 'none',
                            borderRadius: '9px 9px 0 0',
                            layoutModifiers: ["month-left-align"],
                            eventsData: eve,
                            dateChanged: (currentDate, events) => {
                                const events_display = document.querySelector('.events-display');
                                let events_html = '';
                                
                                let selDate = new Date();
                                selDate.setDate(currentDate.getDate());
                                selDate.setMonth(currentDate.getMonth());
                                selDate.setUTCFullYear(currentDate.getUTCFullYear());

                                document.querySelector("#myCal").setAttribute('date', selDate);

                                events.forEach(event => {
                                events_html += 
                                    `<div class="event">
                                        <div class="event-left">
                                            <div class="event-title searchable" data-id="${event.agentId}" id="${event.agentName}">
                                                <div id="unscheduleBotMsg-container` + event.id + event.agentId + `">
                                                    <p id="unscheduleBot-msg` + event.id + event.agentId + `"></p>
                                                </div>
                                                <span>
                                                    <span>
                                                        ${event.name}
                                                    </span>
                                                    <br>
                                                    <span style="color: #c05ec3;">
                                                    [${event.agentName}]
                                                    </span>
                                                </span> 
                                                <span>`+event.start.slice(-8)+`
                                                </span>
                                                <span>
                                                    <button class="MuiButtonBase-root MuiIconButton-root MuiChip-deleteIcon MuiChip-deleteIconColorSecondary" tabindex="0" type="button" style="color: rgb(255, 255, 255);">
                                                        <span class="MuiIconButton-label">
                                                            <svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true" sid="${event.id}" aid="${event.agentId}">
                                                                <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"/>
                                                            </svg>
                                                        </span>
                                                        <span class="MuiTouchRipple-root"/>
                                                        </span>
                                                    </button>
                                                </span>
                                                    
                                            </div>
                                        </div>
                                    </div>`;
                                });
                                 
                                 $(document).off('click', ".MuiSvgIcon-root").on('click', ".MuiSvgIcon-root", function (ev) {
                                    let sid = ev.currentTarget.getAttribute('sid');
                                    let aid = ev.currentTarget.getAttribute('aid');
                                    const scheduleBotMsgContainer = $('#unscheduleBotMsg-container' + sid + aid);
                                    const scheduleBotMsg = $('#unscheduleBot-msg' + sid + aid);
                                    
                                    if (scheduleBotMsg[0] == null)
                                        return;
                                        
                                    const container = scheduleBotMsg[0].parentNode.parentNode.parentNode.parentNode;  
                                    
                                    fetch(process.env.REACT_APP_TAP_NEXUS + '/api/agents/' + aid + '/schedule/remove?sid=' + sid, {
                                        method: 'get',
                                        async: 'true',
                                        headers: {
                                            'Content-type': 'application/json',
                                            AUTHORIZATION: `Bearer ${accessToken}`
                                        }
                                    }).then(function (response) 
                                    {
                                        if (response.status == 200) {
                                            container.classList.toggle('hide-event');
                                            //remove event from data set
                                            let events = mycal.getEventsData();
                                            events = events.filter(x => x.id != sid);
                                            mycal.setEventsData(events);
                                            mycal.reset(mycal.getSelectedDate());
                                        }
                                        else {
                                            let errorMsg;
                                            response.text().then(x => {
                                                
                                                console.log(x);
                                                console.log('Something went wrong.', response);
                                                toast.error('Could not delete scheduled task.\n' + x, {
                                                    position: 'top-right',
                                                    autoClose: 3000,
                                                    hideProgressBar: false,
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: true,
                                                    progress: undefined
                                                  });
                                            });
                                        }
                                    })
                                        .catch(function (error) {
                                        console.log('Something went wrong.', error);
                                        scheduleBotMsgContainer.fadeIn(500).delay(2000).fadeOut(500);
                                        scheduleBotMsg.text('something went wrong. please try again')
                                        scheduleBotMsg.addClass('red-warning')
            
                                        });
                                });
        

                                if (events_html) {
                                    events_display.innerHTML = events_html;
                                    $(document).on("click", "#expand", function (ev) {
                                        let hideElements = this.parentNode.nextElementSibling;
                                        hideElements.classList.toggle('open-accordion2');
                                    })
                                } else {
                                    events_display.innerHTML = '<div class="no-events-text">No scheduled bots on this day</div>';

                                }

                            },

                            monthChanged: (currentDate, events) => {
                                // console.log("month change", currentDate, events);
                            }
                        })
                }

            )

        },
        error: function (response) {
            // alert('error');
        }
    })

    
    return (
        <div>
            <div id="myCal">

            </div>
            <div className="events-display"></div>

            <p className="allBots-title">All bots</p>
            <div className="allBots events-display">

            </div>
        </div>
    )

}


export default CalendarComponent;
