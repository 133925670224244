import React from 'react';

import Grid from '@material-ui/core/Grid';

import { AgentTblCmp } from './agents/agent-list.component';
import { BotTblCmp } from './bot-tbl.component';

import { useUserContext } from '../../../hooks/useUserContext';
import { ParseJwt } from '../../../hooks/useUserContext';

import ForbiddenView from '../../__common/4xx/403.component';

const BotCmp = () => {
  const { accessToken } = useUserContext();
  const orchEnabled = ParseJwt(accessToken).orchEnabled;
  if (orchEnabled != 'True')
	  return (<ForbiddenView/>);

  return (
    <div id="gigilica" style={{ overflowX: 'hidden', background:'#202447' }}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <div>
            <BotTblCmp />
          </div>
        </Grid>
        {/*<Grid*/}
        {/*  item*/}
        {/*  xs={1}*/}
        {/*  style={{*/}
        {/*    border: 'none'*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <AgentTblCmp />*/}
        {/*</Grid>*/}
      </Grid>
    </div>
  );
};

export default BotCmp;
