import React from 'react';
import { useRoutes } from 'react-router-dom';

import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-toastify/dist/ReactToastify.css';

import { ThemeProvider } from '@material-ui/core';

import '../src/mixins/chartjs';
import './App.sass';

import routes from '../src/routes';
import { theme } from './components/theme/theme-builder';
import { GlobalStyles } from '../src/components/GlobalStyles';

import { UserContext } from './app/contexts/userContext';

const initialState = {
  id: '',
  accessToken: ''
};

const App = () => {
  const routing = useRoutes(routes);
  
  const url = window.location.origin
  if (url != null)
  {
    if (!url.includes('localhost') && !url.includes('https')) 
    {
      window.location = `https:${url.split(':')[1]}`
    }
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <UserContext.Provider value={initialState}>
          {routing}
        </UserContext.Provider>
      </ThemeProvider>
    </>
  );
};

export default App;
