import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import ContentLoader from 'react-content-loader';

import PulseLoader from './PulseLoader';
import MoonLoader from './MoonLoader';
import DotLoader from './DotLoader';

const useStyles = makeStyles(theme => ({
  root: {},
  navLoader: {
    height: '100vh',
    width: 256
  },
  contentLoaderBg: {
    width: '100%',
    backgroundColor: '#212447',
    display: 'flex',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center'
  },
  contentLoaderContainer: {
    width: '90%',
    display: 'flex',
    justifyContent: 'space-between',
    margin: 'auto',
    backgroundColor: '#212447',
    flexWrap: 'wrap',
    height: 'fit-content',
    padding: '24px 0'
  }
}));

function randomNumber(min, max) {
  const rand = Math.random() * (max - min) + min;
  return Math.floor(rand);
}

export const RandomLoader = () => {
  const classes = useStyles();

  const rand = randomNumber(0, 5);

  return rand % 2 === 0 ? (
    <div className={classes.contentLoaderBg} style={{ overflowX: 'hidden' }}>
      <_MoonLoader style={{ overflowX: 'hidden' }} />
    </div>
  ) : (
    <div className={classes.contentLoaderBg} style={{ overflowX: 'hidden' }}>
      <_PulseLoader style={{ overflowX: 'hidden' }} />
    </div>
  );
};

export const _MoonLoader = () => {
  const classes = useStyles();

  return (
    <div className={classes.contentLoaderBg} style={{ overflowX: 'hidden' }}>
      <MoonLoader loading={true} style={{ overflowX: 'hidden' }} />
    </div>
  );
};

export const _PulseLoader = () => {
  const classes = useStyles();

  return (
    <div className={classes.contentLoaderBg} style={{ overflowX: 'hidden' }}>
      <PulseLoader loading={true} style={{ overflowX: 'hidden' }} />
    </div>
  );
};

export const _DotLoader = () => {
  const classes = useStyles();

  return (
    <div className={classes.contentLoaderBg} style={{ overflowX: 'hidden' }}>
      <DotLoader loading={true} style={{ overflowX: 'hidden' }} />
    </div>
  );
};

// export const DashboardLoader = () => {
//   const classes = useStyles();
//   return (
//     <div style={{ display: 'flex' }}>
//       <ContentLoader
//         className={classes.navLoader}
//         backgroundColor="#050a31"
//         foregroundColor="#141c52"
//       >
//         <rect x="0" y="0" rx="0" ry="0" width="256" height="100%" />
//       </ContentLoader>
//       <div className={classes.contentLoaderBg}>
//         <div className={classes.contentLoaderContainer}>
//           <ContentLoader
//             style={{ height: '200px', width: '22%' }}
//             backgroundColor="#050a31"
//             foregroundColor="#141c52"
//           >
//             <rect x="0" y="0" rx="10" ry="10" width="100%" height="200" />
//           </ContentLoader>
//           <ContentLoader
//             style={{ height: '200px', width: '22%' }}
//             backgroundColor="#050a31"
//             foregroundColor="#141c52"
//           >
//             <rect x="0" y="0" rx="10" ry="10" width="100%" height="200" />
//           </ContentLoader>
//           <ContentLoader
//             style={{ height: '200px', width: '22%' }}
//             backgroundColor="#050a31"
//             foregroundColor="#141c52"
//           >
//             <rect x="0" y="0" rx="10" ry="10" width="100%" height="200" />
//           </ContentLoader>
//           <ContentLoader
//             style={{ height: '200px', width: '22%' }}
//             backgroundColor="#050a31"
//             foregroundColor="#141c52"
//           >
//             <rect x="0" y="0" rx="10" ry="10" width="100%" height="200" />
//           </ContentLoader>

//           <ContentLoader
//             style={{ height: '200px', width: '48%', marginTop: '20px' }}
//             backgroundColor="#050a31"
//             foregroundColor="#141c52"
//           >
//             <rect x="0" y="0" rx="10" ry="10" width="100%" height="200" />
//           </ContentLoader>
//           <ContentLoader
//             style={{ height: '200px', width: '48%', marginTop: '20px' }}
//             backgroundColor="#050a31"
//             foregroundColor="#141c52"
//           >
//             <rect x="0" y="0" rx="10" ry="10" width="100%" height="200" />
//           </ContentLoader>
//           <ContentLoader
//             style={{ height: '200px', width: '48%', marginTop: '20px' }}
//             backgroundColor="#050a31"
//             foregroundColor="#141c52"
//           >
//             <rect x="0" y="0" rx="10" ry="10" width="100%" height="200" />
//           </ContentLoader>
//         </div>
//       </div>
//     </div>
//   );
// };

// export const ControlLoader = () => {
//   const classes = useStyles();
//   return (
//     <div>
//       <div style={{ width: '100%' }}>
//         <div style={{ width: '95%', margin: 'auto' }}>
//           <ContentLoader
//             style={{
//               width: '25%',
//               position: 'relative',
//               top: '30px',
//               marginLeft: '7px'
//             }}
//             backgroundColor="#050a31"
//             foregroundColor="#141c52"
//           >
//             <rect x="0" y="0" rx="10" ry="10" width="100%" height="48" />
//           </ContentLoader>
//           <ContentLoader
//             style={{
//               width: '100%',
//               height: '100vh',
//               margin: '-43px auto 0 7px'
//             }}
//             backgroundColor="#050a31"
//             foregroundColor="#141c52"
//           >
//             <rect x="0" y="0" rx="10" ry="10" width="100%" height="100%" />
//           </ContentLoader>
//         </div>
//       </div>
//     </div>
//   );
// };
