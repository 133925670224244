/* eslint-disable */
import {render} from 'react-dom';
import './index.css';
import * as React from 'react';
import {
    Day,
    Week,
    Month,
    ScheduleComponent,
    ResourcesDirective,
    ResourceDirective,
    Inject,
    Resize
} from '@syncfusion/ej2-react-schedule';
import {extend} from '@syncfusion/ej2-base';
import {SampleBase} from './sample-base';
import { DataManager, UrlAdaptor, ODataV4Adaptor } from '@syncfusion/ej2-data';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import {Query, Predicate} from '@syncfusion/ej2-data';
import {CheckBoxComponent} from '@syncfusion/ej2-react-buttons';
import {PropertyPane} from './property-pane';
import * as dataSource from './datasource.json';
import {dropDownSelect} from "@syncfusion/ej2-react-richtexteditor";
import {useState} from "react";
// import {useUserContext} from "../../../hooks/useUserContext";
import {useInterval} from '../../../hooks/useInterval';
import axios from 'axios';
import {UserContext} from "../../../contexts/userContext";
import { Ajax } from '@syncfusion/ej2-base';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import $ from 'jquery';


export const useUserContext = () => {
    const context = React.useContext(UserContext);
    return localStorage.user ? JSON.parse(localStorage.user) : context;
};

export const clearUserState = () => {
    if (localStorage.user) {
        localStorage.removeItem(localStorage.user);
    }
};

class CustomAdaptor extends ODataV4Adaptor {

    processResponse() {
        let i = 0;
        // calling base class processResponse function
        let original = super.processResponse.apply(this, arguments);
        // adding event id
        original.forEach((item) => item['EventID'] = ++i);
        return original;
    }
}


export default class CompanyScheduler extends SampleBase {

    constructor() {

        let accessToken = JSON.parse(localStorage.user).accessToken
        let token = `Bearer ${accessToken}`
        
        console.log()
        // const {accessToken} = useUserContext();
        super(...arguments);


        // this.data = extend([], dataSource.resourceSampleData, null, true);
        // this.resourceData = [
        //     {Text: 'Agent 1', Id: 1, Color: '#ea7a57'},
        //     {Text: 'Agent 2', Id: 2, Color: '#df5286'},
        //     {Text: 'Agent 3', Id: 3, Color: '#865fcf'}
        // ];


    }

    loadEvents() {

        let ajax = new Ajax(
            process.env.REACT_APP_TAP_NEXUS + 'api/agents/fb763d42-54ce-4584-8ad2-050ba30c1131/schedule',
            'GET',
            false,

        );

        ajax.onSuccess = function(value) {
            var schObj = document.querySelector('.e-schedule').ej2_instances[0];
            const options = JSON.parse(value);


             const newOp =  options.map(function(bot) {
                return { Id : bot.botId, Subject : bot.botName,
                    StartTime: bot.startTime,
                    EndTime: bot.endTime,
                }

            })

            console.log(newOp)
            schObj.eventSettings.dataSource = newOp;
             console.log(JSON.parse(value))

        };
        ajax.beforeSend = function(args) {
            let accessToken = JSON.parse(localStorage.user).accessToken
            let token = `Bearer ${accessToken}`
                args.httpRequest.setRequestHeader("Authorization",  token);
            }

        ajax.send();

    }

    // btnClick() {
    //     var ajax = new Ajax(
    //         'https://ej2services.syncfusion.com/production/web-services/api/Schedule',
    //         'GET',
    //         false
    //     );
    //     ajax.onSuccess = function (value) {
    //         var schObj = document.querySelector('.e-schedule').ej2_instances[0];
    //         schObj.eventSettings.dataSource = JSON.parse(value);
    //         console.log(JSON.parse(value));
    //
    //     };
    //     ajax.send();
    // }

    onChange() {
        this.loadEvents()
        let predicate;
        let proxy = this;
        let checkBoxes = [this.ownerOneObj, this.ownerTwoObj, this.ownerThreeObj];
        checkBoxes.forEach((checkBoxObj) => {
            if (checkBoxObj.checked) {
                if (predicate) {
                    predicate = predicate.or('OwnerId', 'equal', parseInt(checkBoxObj.value, 10));
                } else {
                    predicate = new Predicate('OwnerId', 'equal', parseInt(checkBoxObj.value, 10));
                }
            }
        });
        proxy.scheduleObj.eventSettings.query = new Query().where(predicate);
    }

    onPopupOpen(args) {
        args.cancel = true;
    }
    onCellClick(args) {
        args.cancel = true;
    }
    onAppointmentWindowOpen(args) {
        args.cancel = true;
    }

    render() {
        return (
            <div className='schedule-control-section'>
                {/*<ButtonComponent*/}
                {/*    cssClass="e-flat e-primary"*/}
                {/*    ref={scope => {*/}
                {/*        this.btnobj = scope;*/}
                {/*    }}*/}
                {/*    onClick={this.loadEvents.bind(this)}*/}
                {/*>*/}
                {/*    Click to load the events through Ajax call*/}
                {/*</ButtonComponent>*/}
            <div className='col-lg-9 control-section'>

                <div className='control-wrapper'>
                    <ScheduleComponent cellClick={this.onCellClick}
                                       // ref={(schedule) => (this.scheduleObj = schedule)}
                                       popupOpen={this.onPopupOpen.bind(this)}
                                       cssClass='resource' width='100%' height='650px'
                                       selectedDate={new Date()}
                                       ref={scope => {
                                           this.btnobj = scope;
                                           // this.loadEvents.bind(this)
                                       }}
                                       onClick={this.loadEvents.bind(this)}
                                     >
                        <ResourcesDirective>
                            <ResourceDirective field='OwnerId' title='Owners' name='Owners' allowMultiple={true}
                                               dataSource={this.dataSource} textField='Text' idField='Id'
                                               colorField='Color'>
                            </ResourceDirective>
                        </ResourcesDirective>

                        <Inject services={[Day, Week, Month, Resize]}/>


                    </ScheduleComponent>
                </div>
            </div>
            <div className='col-lg-3 property-section'>
                <PropertyPane title='Properties'>
                    <table id="property" title="Resources" className='property-panel-table'>
                        <tbody>
                        <tr>
                            <td>
                                <CheckBoxComponent ref={checkboxObj => this.ownerOneObj = checkboxObj} value='1'
                                                   id='margaret' cssClass='margaret' checked={true} label='Agent 1'
                                                   change={this.onChange.bind(this)}></CheckBoxComponent>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <CheckBoxComponent ref={checkboxObj => this.ownerTwoObj = checkboxObj} value='2'
                                                   id='robert' cssClass='robert' checked={true} label='Agent 2'
                                                   change={this.onChange.bind(this)}></CheckBoxComponent>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <CheckBoxComponent ref={checkboxObj => this.ownerThreeObj = checkboxObj} value='3'
                                                   id='laura' cssClass='laura' checked={true} label='Agent 3'
                                                   change={this.onChange.bind(this)}></CheckBoxComponent>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </PropertyPane>
            </div>
        </div>);
            // <div className="schedule-control-section">
            //     <div className="control-section">
            //         <div className="control-wrapper">
            //             <ButtonComponent
            //                 cssClass="e-flat e-primary"
            //                 ref={(scope) => {
            //                     this.btnobj = scope;
            //                 }}
            //                 onClick={this.btnClick.bind(this)}
            //             >
            //                 Click to load the events through Ajax call
            //             </ButtonComponent>
            //             <ScheduleComponent
            //                 ref={(schedule) => (this.scheduleObj = schedule)}
            //                 width="100%"
            //                 height="650px"
            //                 selectedDate={new Date(2020, 10, 22)}
            //             >
            //                 <Inject services={[Day, Week, Month]} />
            //             </ScheduleComponent>
            //         </div>
            //     </div>
            // </div>
        // )
    }
}

