import React from 'react';

import { UserContext } from '../contexts/userContext';

function parseJwt (token) {
  if (token == "")
    return {orchEnabled: 'False'};

  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};


export const ParseJwt = (token)=>{
  return parseJwt(token);
};

export const useUserContext = () => {
  const context = React.useContext(UserContext);
  
  return localStorage.user ? JSON.parse(localStorage.user) : context;
};

export const clearUserState = () => {
  if (localStorage.user) {
    localStorage.removeItem(localStorage.user);
  }
};
