import React from 'react';

import { makeStyles } from '@material-ui/core';

import Page from '../__common/layouts/Page';

import ActionsImg from '../../../icons/img/actions.png';
import { TimeSavedLineChart } from './bot.time-saved-linechart';
import TimeSavedImg from '../../../icons/img/time_saved.png';
import { TimeSaved } from './bot.time-saved-chart';

import { TotalBotsPanel } from './bots.assigned-panel';
import { UnassignedBots } from './bots.unassigned-panel';
import { BotsExecuted } from './bots.executed-panel';
import { AgentsPanel } from './agents.panel';
import { BotsDoughnutChart } from './doughnut.bots-chart';
import { AgentsDoughnutChart } from './doughnut.agents-chart';

import { useUserContext } from '../../hooks/useUserContext';
import { ParseJwt } from '../../hooks/useUserContext';

import ForbiddenView from '../__common/4xx/403.component';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    margin: 'auto',
    alignItems: 'center',
    display: 'flex',
    maxWidth: 1200
  }
}));

export const Dashboard = () => {
  const classes = useStyles();
  const { accessToken } = useUserContext();

  const orchEnabled = ParseJwt(accessToken).orchEnabled;
  if (orchEnabled != 'True')
    return (<ForbiddenView/>);

  return (
    <Page className={classes.root} title="Dashboard">
      <div style={{ height: 'fit-content', width: '100%' }}>
        <div className="panels">
          <div className="panelTotalBots">
            <TotalBotsPanel />
          </div>

          <div className="panelNotAssigned">
            <UnassignedBots />
          </div>

          <div className="panelBotsExecuted">
            <BotsExecuted />
          </div>

          <div className="panelAgents">
            <AgentsPanel />
          </div>
        </div>

        <div className="panels" style={{ marginTop: '20px' }}>
          <div
            className="panelDoughnut"
            style={{
              width: '48%',
              display: 'flex',
              justifyContent: 'space-around'
            }}
          >
            <BotsDoughnutChart />
            <AgentsDoughnutChart />
          </div>
          <div className="panelChart" style={{ width: '48%' }}>
            <img
              style={{
                height: '100%',
                borderRadius: '10px',
                opacity: '.5',
                display: 'table',
                margin: 'auto'
              }}
              src={ActionsImg}
              alt=""
            />
          </div>
        </div>
        <div className="panels" style={{ marginTop: '20px' }}>
          <div className="panelChart" style={{ width: '48%' }}>
            <img
              style={{
                height: '100%',
                borderRadius: '10px',
                opacity: '.5',
                display: 'table',
                margin: 'auto'
              }}
              src={TimeSavedImg}
              alt="Time saved chart"
            />
          </div>
          {/* <div className="panelChart" style={{ width: '48%' }}>
            <TimeSaved />
            <TimeSavedLineChart />
          </div> */}
        </div>
      </div>
    </Page>
  );
};
