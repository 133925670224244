import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { makeStyles, Typography } from '@material-ui/core';

import { NavBar } from './left-nav.menu';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',

    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256,
      backgroundColor: '#212447'
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

export const LeftSideNavOutlet = () => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = React.useState(false);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!localStorage.user || !localStorage.user.length > 0) navigate('/login');
  }, [navigate]);

  return (
    <div className={classes.root}>
      <Typography>ceva</Typography>
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />

      <div className={classes.wrapper}>
        <div className={classes.contentContainer} id="cnt">
          <div className={classes.content}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};
