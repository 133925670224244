import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Container, Grid, makeStyles } from '@material-ui/core';

import { ProfileAvatar } from './user.profile-avatar.component';
import { ProfileDetails } from './user.profile-details.component';
import { ChangePassword } from './user.profile.change-password.component';

import Page from '../../__common/layouts/Page';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

export const Account = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!localStorage.user || !localStorage.user.length > 0) navigate('/login');
  }, [navigate]);

  return (
    <Page
      className={classes.root}
      title="Account"
      style={{ paddingTop: '80px' }}
    >
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          {/* <Grid item lg={3} md={6} xs={12} className={'card'}>
            <ProfileAvatar />
          </Grid> */}
          <Grid item lg={6} md={6} xs={12}>
            <ProfileDetails />
          </Grid>
          <Grid item lg={6} md={6} xs={12}>
            <ChangePassword />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};
