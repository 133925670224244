import React from 'react';

import Grid from '@material-ui/core/Grid';

import { AgentTblCmp } from './agent-tbl.component';
import { MiniScheduler } from '../../scheduler/mini-scheduler/organization.schedule';
import { BotTblCmp } from './bots/bot-tbl.component';
import CalendarComponent from "../../__common/companyScheduler/companyScheduler2";

import { useUserContext } from '../../../hooks/useUserContext';
import { ParseJwt } from '../../../hooks/useUserContext';

import ForbiddenView from '../../__common/4xx/403.component';

const AgentCmp = () => {
  const { accessToken } = useUserContext();
  const orchEnabled = ParseJwt(accessToken).orchEnabled;
  if (orchEnabled != 'True')
	  return (<ForbiddenView/>);

  return (
    <div style={{ overflowX: 'hidden', background: '#202447', height: '100%' }}>
      <Grid container>
        <Grid item xs={8}>
          <AgentTblCmp />
        </Grid>

        {/*<Grid item xs={3}>*/}
        {/*  <MiniScheduler />*/}
        {/*</Grid>*/}

        <Grid
          item
          xs={4}
          style={{
            border: 'none'
          }}
        >
          <CalendarComponent style={{marginTop: '85px'}}/>
          {/*<BotTblCmp />*/}
        </Grid>
      </Grid>
    </div>
  );
};

export default AgentCmp;
