import React from 'react';

import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import DesktopWindowsIcon from "@material-ui/icons/DesktopWindows";

export const AssignedToAgentsCmp = ({
  bot,
  selectedAgent,
  onRemoveAgent,
  ...rest
}) => {
  return bot.agents.map(agent => {
    return (
      <Chip
        style={{
          fontSize: '12px',
          backgroundColor: '#202447',
          margin: '10px 5px'
        }}
        key={agent.id}
        icon={
          <DesktopWindowsIcon
          style={{width: 15}}
          />
        }
        label={agent.name}
        onDelete={() => onRemoveAgent(bot.id, agent.id)}
        color="secondary"
        deleteIcon={
          <IconButton
            style={{ color: '#fff' }}
            disabled={
              (selectedAgent.id === agent.id &&
                selectedAgent.guard &&
                selectedAgent.botId == bot.id) ||
              agent.isRunning
            }
          >
            <CancelIcon />
          </IconButton>
        }
      />
    );
  });
};
