import React from 'react';

import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';

import { makeStyles, fade } from '@material-ui/core/styles';

import axios from 'axios';

import { useUserContext } from '../../../../hooks/useUserContext';
import { ToInfinityAndBeyond } from '../../../__common/scrollable/infinity-scroll';

const ActiveAgentItemCmp = ({ element, ...rest }) => (
  <>
    <div className="aside-item" style={{ justifyContent: 'space-between' }}>
      {element.name}
      <DesktopWindowsIcon
        fontSize="small"
        style={{ marginLeft: '10px', verticalAlign: 'middle' }}
      />
      {element.botCount}
    </div>
  </>
);

const actionDescriptor = {
  ON_FETCHED: 'ON_FETCHED',
  ON_FETCH_MORE: 'ON_FETCH_MORE'
};

const initialState = {
  loading: true,

  filter: '',

  pageNo: 1,
  pageSize: 5,
  totalCount: 0,
  hasMore: false,

  agents: [],

  error: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionDescriptor.ON_FETCHED: {
      return {
        ...state,
        loading: false,
        agents: state.agents.concat(action.payload.agents),
        totalCount: action.payload.totalCount,
        hasMore: action.payload.totalCount > state.agents.length
      };
    }

    case actionDescriptor.ON_FETCH_MORE: {
      return {
        ...state,
        pageNo: state.pageNo + 1
      };
    }
  }
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  pagionationRoot: {
    flexGrow: 1
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto'
    }
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputRoot: {
    color: 'inherit'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '15ch',
      '&:focus': {
        width: '35ch'
      }
    }
  }
}));

export const ActiveAgentsListCmp = () => {
  const classes = useStyles();
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const { accessToken } = useUserContext();

  React.useEffect(() => {
    let cancellationToken;

    axios({
      method: 'GET',
      url: `${
        process.env.REACT_APP_TAP_NEXUS
      }/api/agents/active?skip=${(state.pageNo - 1) * state.pageSize}&take=${
        state.pageSize
      }`,
      cancelToken: new axios.CancelToken(
        canceler => (cancellationToken = canceler)
      ),
      headers: {
        'CONTENT-TYPE': 'application/json',
        AUTHORIZATION: `Bearer ${accessToken}`
      }
    })
      .then(response => {
        dispatch({
          type: actionDescriptor.ON_FETCHED,
          payload: {
            agents: response.data.value.map(item => {
              return {
                id: item.id,
                name: item.name,
                botCount: item.botCount
              };
            }),
            totalCount: response.data.count
          }
        });
      })
      .catch(error => {
        if (axios.isCancel(error)) return;
      });

    return () => cancellationToken();
  }, [state.pageNo, state.filter]);

  return (
    <>
      <div>
        {/* <Toolbar>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              onChange={event => onSearch(event)}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
          </div>
        </Toolbar> */}
        <Paper
          className={classes.paper}
          style={{ backgroundColor: '#202447', paddingBottom: '70px' }}
        >
          <ToInfinityAndBeyond
            hasMore={state.hasMore}
            loading={state.loading}
            source={state.agents}
            totalCount={state.totalCount}
            renderedComponent={<ActiveAgentItemCmp />}
            onFetchMore={() => {
              dispatch({
                type: actionDescriptor.ON_FETCH_MORE
              });
            }}
          />
        </Paper>
      </div>
    </>
  );
};
