import React from 'react';

import { makeStyles, useTheme } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';

import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

import { AssignedBotsListCmp } from './bot-list.assigned.component';
import { UnassignedBotsListCmp } from './bot-list.unassigned.component';

import IconButton from '@material-ui/core/IconButton';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const TabPanel = ({ children, value, index, ...other }) => (
  <div>
    {value === index && (
      <Box p={3}>
        <Typography>{children}</Typography>
      </Box>
    )}
  </div>
);

const useStyles = makeStyles(theme => ({
  root: {
    width: '3%',
    position: 'fixed',
    right: '0',
    top: '0',
    height: '100%',
    overflow: 'visible',
    backgroundColor: '#212447',
    boxShadow: '0px 3px 55px #00000075',
    borderRadius: '51px 0 0 0'
  },
  items: {
    backgroundColor: 'black'
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    top: '60px',
    borderRadius: '31px 0 0 0'
  },
  appBarShift: {
    top: '60px',
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginRight: drawerWidth
  },
  title: {
    flexGrow: 1
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    backgroundColor: '#202447',
    boxShadow: '0px 3px 55px #00000075'
  },
  drawerLabel: {
    color: 'white',
    transform: 'rotate(-90deg)',
    top: 140,
    position: 'relative'
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginRight: -drawerWidth
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginRight: 0
  },

  open: {
    position: 'absolute',
    background: '#bf5dc3',
    borderRadius: '50%',
    padding: 5,
    height: 37,
    width: 37,
    left: -10,
    top: 50
  },
  close: {
    position: 'absolute',
    background: '#202447',
    borderRadius: '50%',
    padding: 5,
    height: 37,
    width: 37,
    left: -24,
    top: 16,
    zIndex: 100
  },
  drawerTitle: {
    fontSize: 40,
    fontFamily: 'Quicksand, sans-serif',
    paddingTop: 40
  }
}));

const drawerWidth = 350;

export const BotTblCmp = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [selected, setSelected] = React.useState(0);
  const [open, setOpen] = React.useState(false);

  const onSelected = (event, selected) => {
    setSelected(selected);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      {/* TODO : RMS : load only if the button is clicked */}
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="end"
        onClick={handleDrawerOpen}
        className={clsx(open && classes.hide)}
      >
        <ChevronLeftIcon className={classes.open} />
      </IconButton>
      <Typography className={classes.drawerLabel}>Orchestrate</Typography>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <AppBar position="static" className={classes.appBar}>
          <div className={classes.drawerHeader}>
            <IconButton>
              {theme.direction === 'rtl' ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon
                  className={
                    classes.close
                  } /**TODO : Warning: Failed prop type: Material-UI: You are providing
            an onClick event listener to a child of a button element. Firefox
            will never trigger the event. You should move the onClick listener
            to the parent button element.
            https://github.com/mui-org/material-ui/issues/13957 in
            ForwardRef(IconButton) (created by
            WithStyles(ForwardRef(IconButton))) in
            WithStyles(ForwardRef(IconButton)) (at bot-tbl.component.js:178) in
            div (at bot-tbl.component.js:177) in header (created by
            ForwardRef(Paper)) in ForwardRef(Paper) (created by
            WithStyles(ForwardRef(Paper))) in WithStyles(ForwardRef(Paper))
            (created by ForwardRef(AppBar)) in ForwardRef(AppBar) (created by
            WithStyles(ForwardRef(AppBar))) in WithStyles(ForwardRef(AppBar))
            (at bot-tbl.component.js:176) in div (created by ForwardRef(Paper))
            in ForwardRef(Paper) (created by WithStyles(ForwardRef(Paper))) in
            WithStyles(ForwardRef(Paper)) (created by Transition) in Transition
            (created by ForwardRef(Slide)) in ForwardRef(Slide) (created by
            ForwardRef(Drawer)) in div (created by ForwardRef(Drawer)) in
            ForwardRef(Drawer) (created by WithStyles(ForwardRef(Drawer))) in
            WithStyles(ForwardRef(Drawer)) (at bot-tbl.component.js:170) in div
            (at bot-tbl.component.js:158) in BotTblCmp (at main.component.js:22)
            in div (created by ForwardRef(Grid)) in ForwardRef(Grid) (created by
            WithStyles(ForwardRef(Grid))) in WithStyles(ForwardRef(Grid)) (at
            main.component.js:15) in div (created by ForwardRef(Grid)) in
            ForwardRef(Grid) (created by WithStyles(ForwardRef(Grid))) in
            WithStyles(ForwardRef(Grid)) (at main.component.js:11) in div (at
            main.component.js:10) in AgentCmp (at routes.js:32) in Outlet (at
            controlCenterMenuLayout.js:20) in div (at
            controlCenterMenuLayout.js:18) in ControlCenterMenuLayout (at
            routes.js:27) in Outlet (at DashboardLayout/index.js:57) in div (at
            DashboardLayout/index.js:56) in div (at DashboardLayout/index.js:55)
            in div (at DashboardLayout/index.js:54) in div (at
            DashboardLayout/index.js:46) in DashboardLayout (at routes.js:21) in
            ThemeProvider (at App.js:28) in App (at src/index.js:9) in Router
            (created by BrowserRouter) in BrowserRouter (at src/index.js:8) */
                  onClick={handleDrawerClose}
                />
              )}
            </IconButton>
            <p className={classes.drawerTitle}>Bots</p>
          </div>
          <Tabs value={selected} onChange={onSelected}>
            <Tab label="Assigned" />
            <Tab label="Unassigned" />
          </Tabs>
        </AppBar>

        <TabPanel value={selected} index={0}>
          <AssignedBotsListCmp />
        </TabPanel>
        <TabPanel value={selected} index={1}>
          <UnassignedBotsListCmp />
        </TabPanel>
      </Drawer>
    </div>
  );
};
