import React from 'react';
import { Link } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },

}));

export const TablePaging = ({
  pageNo,
  pageCount,
  linkTo,
  onChange,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container alignItems="center" justify="center">
        <Grid item xs="auto">
          <Box display="flex" width={500} height={80}>
            <Box m="auto">
              <Pagination
                page={Number(pageNo)}
                count={pageCount}
                shape="rounded"
                color="primary"
                showLastButton={pageCount > 7}
                showFirstButton={pageCount > 7}
                hideNextButton={pageCount === 1}
                hidePrevButton={pageCount === 1}
                boundaryCount={2}
                onChange={(event, page) => onChange(event, page)}
                renderItem={item => (
                  <PaginationItem
                    type={'start-ellipsis'}
                    component={Link}
                    selected
                    to={linkTo}
                    {...item}

                  />
                )}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};
