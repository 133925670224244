import React from 'react';

import { Link, useLocation, useNavigate } from 'react-router-dom';

import PropTypes from 'prop-types';

import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Hidden from '@material-ui/core/Hidden';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { makeStyles } from '@material-ui/core/styles';

import { User as UserIcon } from 'react-feather';

import { NavItem } from './left-nav.item';
import { TailentLogo } from '../__common/Logos/tailent.logo';

import { useUserContext } from '../../hooks/useUserContext';
import { ParseJwt } from '../../hooks/useUserContext';


const menuItems = [
  {
    href: 'dashboard',
    title: 'Dashboard',
  },
  {
    href: '/account',
    icon: UserIcon,
    title: 'Account'
  },
  {
    href: 'control-center/bots',
    title: 'Bots'
  },
  {
    href: 'control-center/agents',
    title: 'Agents'
  },

  // {
  //   href: '/scheduler',
  //   title: 'Calendar'
  // }

];

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    height: '100%'
  },
  navTitle: {
    color: '#fff',
    fontSize: '1.6rem'
  },
  avatar: {
    cursor: 'pointer',
    width: 31,
    height: 31,
    marginRight: 10
  },

  userDetails: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  name: {
    fontSize: 14
  },
  toolbar: {
    marginTop: '1rem'
  },
  nav: {
    marginTop: '6rem'
  },
  logoutContainer: {
    width: '100%'
  },
  logout: {
    background:
      'transparent linear-gradient(90deg, #B063A7 0%, #B063A7 60%, #5A78BA 100%) 0% 0% no-repeat padding-box',
    width: 100,
    borderRadius: 32,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '1rem'
  }
}));

export const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();

  const [user, setUser] = React.useState({
    avatar: '',
    name: ''
  });

  const { accessToken } = useUserContext();
  const orchEnabled = ParseJwt(accessToken).orchEnabled;
  
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    localStorage.removeItem('user');
    navigate('/login');
  };
  React.useEffect(() => {
    if (!localStorage.user || !localStorage.user.length > 0) {
      navigate('/login');
    } else {
      const userLocal = JSON.parse(localStorage.user);
      setUser({
        avatar: '/static/images/avatars/user.png',
        name: userLocal.username
      });

      if (openMobile && onMobileClose) {
        onMobileClose();
      }
    }
  // eslint-disable-next-line
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      className={'navbar'}
    >
      <Box p={0}>
        <Toolbar className={classes.toolbar}>
          <Link to="/dashboard">
            <TailentLogo />
            <Typography className={classes.navTitle}>
              <span style={{ fontWeight: 900 }}>TAP </span>
              <span>Orchestrator</span>
            </Typography>
          </Link>
        </Toolbar>

        <List className={classes.nav}>
          {menuItems.map(item => {
            if (item.title == "Account" || orchEnabled == 'True')
            return(
            <NavItem
              className={'navItem'}
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />);
            })}
        </List>
      </Box>
      <Box
        flexWrap="nowrap"
        alignItems="center"
        display="flex"
        flexDirection="column"
        p={2}
        className={classes.userDetails}
      >
        <Avatar
          className={classes.avatar}
          component={Link}
          src={user.avatar}
          to="/account"
        />

        <Typography
          className={classes.name}
          color="textPrimary"
          variant="body1"
        >
          {user.name}
        </Typography>

        <Hidden mdDown>
          <ExpandMoreIcon
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
          />
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </Hidden>
      </Box>
      <Box>
        <Typography style={{textAlign: "center"}}>
              v1.5.1700
          </Typography>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          //open
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};
