import React from 'react';

import * as Yup from 'yup';
import { Formik } from 'formik';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import Page from '../../__common/layouts/Page';
import { TailentLogo } from '../../__common/Logos/tailent.logo';

import axios from 'axios';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#050A31',
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

export const SendResetEmailForm = () => {
  const classes = useStyles();

  const onSubmit = ({ email, setSubmitting, resetForm }) => {
    setSubmitting(false);
    axios({
      method: 'PUT',
      url: `${process.env.REACT_APP_TAP_LICENSING}/api/accounts/forgot-password`,
      data: {
        email: email
      },
      headers: {
        'CONTENT-TYPE': 'application/json'
      }
    })
      .then(response => {
        setSubmitting(() => ({
          passChangeSuccess: true
        }));

        window.location = '/login';
      })
      .catch(error => {
        resetForm();
      });
  };

  return (
    <Page className={classes.root} title="Login">
      <div
        style={{
          width: 'fit-content',
          marginTop: '-5px',
          padding: '0 24px'
        }}
      >
        <TailentLogo />
        <Typography style={{ color: '#fff', fontSize: '1.6rem' }}>
          <span style={{ fontWeight: 900 }}>TAP </span>
          <span>Orchestrator</span>
        </Typography>
      </div>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email('Must be a valid email')
                .required('Email is required')
            })}
            onSubmit={({ email }, { setSubmitting, resetForm }) =>
              onSubmit({ email, setSubmitting, resetForm })
            }
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}></Box>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <Typography style={{ color: 'red' }}>
                  {errors.general}
                </Typography>
                <Box my={2}>
                  <Button
                    color="primary"
                    style={{ backgroundColor: '#C05EC3' }}
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Send
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};
