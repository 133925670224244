import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import { licensingHttpClient } from '../../../services/httpClients';
import { useUserContext } from '../../../hooks/useUserContext';

const useStyles = makeStyles(() => ({}));

export const ChangePassword = ({ className, ...rest }) => {
  const classes = useStyles();
  const { accessToken } = useUserContext();

  const [state, setState] = useState({
    id: '',
    email: '',
    currentPassword: '',
    newPassword: '',
    newPasswordConfirmation: '',
    errors: '',
    detail: '',
    showSuccessAlert: false,
    showErrorAlert: false,
    isLoading: false
  });

  const handleStateChange = event => {
    setState({
      ...state,
      [event.target.name]: event.target.value
    });
  };

  useEffect(() => {
    if (localStorage.user) {
      const userLocal = JSON.parse(localStorage.user);

      setState({
        ...state,
        id: userLocal.id,
        email: userLocal.username
      });
    }
  }, []);

  const onSubmit = () => {
    setState({
      ...state,
      isLoading: true
    });

    licensingHttpClient(accessToken)
      .put(`/api/accounts/${state.id}`, state)
      .then(response => {
        if (response.status === 204) {
          setState({
            ...state,
            currentPassword: '',
            newPassword: '',
            newPasswordConfirmation: '',
            errors: '',
            detail: '',
            showSuccessAlert: true,
            showErrorAlert: false,
            isLoading: false
          });
        } else {
          /// route according to status code
          ///
          setState({
            ...state,
            currentPassword: '',
            newPassword: '',
            newPasswordConfirmation: '',
            errors: response.data.errors,
            detail: response.data.detail,
            showSuccessAlert: false,
            showErrorAlert: false,
            isLoading: false
          });
        }
      })
      .catch(error => {
        /// route to 500 page
        ///
        setState({
          ...state,
          currentPassword: '',
          newPassword: '',
          newPasswordConfirmation: '',
          errors: '',
          detail: '',
          showErrorAlert: true,
          showSuccessAlert: false,
          isLoading: false
        });
      });
  };

  return (
    <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card className={'card'} style={{ height: '440px' }}>
        <CardHeader subheader="Update password" title="Password" />
        <Divider />
        {state.showSuccessAlert && (
          <Alert severity="success">Operation completed successfully.</Alert>
        )}
        {state.showErrorAlert && (
          <Alert severity="error">
            Error. Something happened on the server!
          </Alert>
        )}
        <CardContent>
          <TextField
            InputLabelProps={{
              style: { color: '#fff', position: 'absolute' }
            }}
            style={{ marginTop: '0' }}
            className={'input'}
            fullWidth
            label="Current Password"
            margin="normal"
            name="currentPassword"
            onChange={handleStateChange}
            type="password"
            value={state.currentPassword}
            variant="outlined"
          />
          {state.errors && state.errors.CurrentPassword && (
            <Typography style={{ color: 'red' }}>
              {state.errors.CurrentPassword}
            </Typography>
          )}
          <TextField
            className={'input'}
            fullWidth
            label="New Password"
            InputLabelProps={{
              style: { color: '#fff', position: 'absolute' }
            }}
            margin="normal"
            name="newPassword"
            onChange={handleStateChange}
            type="password"
            value={state.newPassword}
            variant="outlined"
          />
          {state.errors && state.errors.NewPassword && (
            <Typography style={{ color: 'red' }}>
              {state.errors.NewPassword}
            </Typography>
          )}
          <TextField
            className={'input'}
            fullWidth
            label="Confirm password"
            InputLabelProps={{
              style: { color: '#fff', position: 'absolute' }
            }}
            margin="normal"
            name="newPasswordConfirmation"
            onChange={handleStateChange}
            type="password"
            value={state.newPasswordConfirmation}
            variant="outlined"
          />
          {state.errors && state.errors.NewPasswordConfirmation && (
            <Typography style={{ color: 'red' }}>
              {state.errors.NewPasswordConfirmation}
            </Typography>
          )}
          {state.detail && (
            <Typography style={{ color: 'red' }}>{state.detail}</Typography>
          )}
        </CardContent>
        <Divider />
        <Typography style={{ color: 'red' }}></Typography>
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button
            className={'btn'}
            disabled={state.isLoading}
            color="secondary"
            variant="contained"
            onClick={onSubmit}
          >
            Update
          </Button>
        </Box>
      </Card>
    </form>
  );
};

ChangePassword.propTypes = {
  className: PropTypes.string
};
