/* eslint-disable */
import React from 'react';

import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Typography from '@material-ui/core/Typography';

import botImg from '../../../icons/Robo_Active.png';

import { useUserContext } from '../../hooks/useUserContext';

import axios from 'axios';

const actionDescriptor = {
  ON_FETCHED: 'ON_FETCHED'
};

const initialState = {
  loading: true,
  totalCount: 0,
  totalRunning: 0,
  totalIdle: 0
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionDescriptor.ON_FETCHED: {
      return {
        loading: false,
        totalCount: action.payload.totalCount,
        totalRunning: action.payload.totalRunning,
        totalIdle: action.payload.totalIdle
      };
    }
  }
};

export const TotalBotsPanel = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const { accessToken } = useUserContext();

  React.useEffect(() => {
    axios({
      method: 'GET',
      url: `${process.env.REACT_APP_TAP_NEXUS}/api/organizations/bots`,
      headers: {
        'CONTENT-TYPE': 'application/json',
        AUTHORIZATION: `Bearer ${accessToken}`
      }
    })
      .then(response => {
        dispatch({
          type: actionDescriptor.ON_FETCHED,
          payload: {
            totalCount: response.data.count,
            totalIdle: response.data.value.countIdle,
            totalRunning: response.data.value.countRunning
          }
        });
      })
      .catch(error => {
        // alert(error);
      });
  }, []);

  return (
    <>
      <div className={'botImgContainer'}>
        <img className="botImg-large" src={botImg} alt="Robo active icon" />
        <Typography className={'botNo'}>{state.totalCount}</Typography>
      </div>

      <div>
        <button className={'botsBtn'}>
          Bots <ChevronRightIcon style={{ color: '#C05EC3' }} />
        </button>
      </div>

      <div className={'runningContainer'}>
        <CheckCircleRoundedIcon
          style={{ color: '#32D74C', marginRight: '5px', fontSize: '16px' }}
        />
        <Typography style={{ color: '#fff', fontSize: '14px' }}>
          {state.totalRunning} Running
        </Typography>
      </div>
    </>
  );
};
