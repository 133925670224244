/* eslint-disable */
import React from 'react';

import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

import { blue } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';

import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';

import axios from 'axios';

import ActiveBotImg from '../../../../icons/Robo_Active.png';
import IdleBotImg from '../../../../icons/Robo_Idle.png';

import { useUserContext } from '../../../hooks/useUserContext';
import { ToInfinityAndBeyond } from '../../__common/scrollable/infinity-scroll';

const useStyles98273592 = makeStyles(theme => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
    backgroundColor: '#202447'
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  }
}));

const SearchCmp = ({ input, ...rest }) => {
  const classes = useStyles98273592();

  return (
    <>
      <IconButton
        type="submit"
        className={classes.iconButton}
        aria-label="search"
      >
        <SearchIcon />
      </IconButton>

      <InputBase
        color="primary"
        placeholder="Search..."
        classes={classes.input}
        inputProps={{ 'aria-label': 'search' }}
      />
      <Divider className={classes.divider} orientation="vertical" />
    </>
  );
};

const BotListItem = ({ element, state, onChange, ...rest }) => (
  <>
    <ListItem
      key={element.id}
      button
      style={{ borderBottom: '1px solid #ffffff38' }}
    >
      <ListItemIcon style={{ color: 'white' }}>
        {element.isAttached ? (
          <img className="botImg" src={ActiveBotImg} alt="Robo active icon" />
        ) : (
          <img className="botImg" src={IdleBotImg} alt="Robo active icon" />
        )}
      </ListItemIcon>
      <ListItemText id={element.id} primary={element.name} />
      <ListItemSecondaryAction>
        <Checkbox
          checked={state.checkedBots.indexOf(element) !== -1}
          disabled={
            element.isRunning || element.id === (state.processingBot?.id ?? 0)
          }
          onChange={() => onChange(element)}
        />
      </ListItemSecondaryAction>
    </ListItem>
  </>
);

const actionDescriptor = {
  ON_FETCHED: 'ON_FETCHED',
  ON_FETCH_MORE: 'ON_FETCH_MORE',

  ON_CHANGE: 'ON_CHANGE',
  ON_CHANGED: 'ON_CHANGED'
};

const initialState = {
  loading: true,

  pageNo: 1,
  pageSize: 5,
  hasMore: false,
  totalCount: 0,

  processingBot: null,

  bots: [],

  checkedBots: [],

  error: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionDescriptor.ON_FETCHED: {
      return {
        ...state,
        loading: false,
        bots: state.bots.concat(action.payload.bots),
        checkedBots: state.checkedBots.concat(
          action.payload.bots.filter(bot => bot.isAttached)
        ),
        totalCount: action.payload.totalCount,
        hasMore: action.payload.totalCount > state.bots.length
      };
    }

    case actionDescriptor.ON_FETCH_MORE: {
      return {
        ...state,
        pageNo: state.pageNo + 1
      };
    }

    case actionDescriptor.ON_CHANGE: {
      return {
        ...state,
        checkedBots: action.payload.updatedList,
        processingBot: action.payload.bot
      };
    }
    case actionDescriptor.ON_CHANGED: {
      return {
        ...state,
        processingBot: null
      };
    }
  }
};

const useStyles02918359827 = makeStyles(theme => ({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600]
  },
  root: {
    width: '100%',
    backgroundColor: '#050A31'
  }
}));

export const AvailableBotsDialog = ({
  open,
  agent,
  onClose,
  onAddBot,
  onRemoveBot,
  ...rest
}) => {
  const classes = useStyles02918359827();
  const { accessToken } = useUserContext();
  const [state, dispatch] = React.useReducer(reducer, initialState);

  React.useEffect(() => {
    let cancellationToken;
    axios({
      method: 'GET',
      url: `${process.env.REACT_APP_TAP_NEXUS}/api/agents/${
        agent.id
      }/bots?skip=${(state.pageNo - 1) * state.pageSize}&take=${
        state.pageSize
      }`,
      cancelToken: new axios.CancelToken(
        canceler => (cancellationToken = canceler)
      ),
      headers: {
        'CONTENT-TYPE': 'application/json',
        AUTHORIZATION: `Bearer ${accessToken}`
      }
    })
      .then(response => {
        dispatch({
          type: actionDescriptor.ON_FETCHED,
          payload: {
            bots: response.data.value.map(bot => {
              return {
                id: bot.id,
                name: bot.name,
                isAttached: bot.isAttached,
                isRunning: bot.isRunning
              };
            }),
            totalCount: response.data.count
          }
        });
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          // alert(error);
          return;
        }
      });

    return () => {
      cancellationToken();
    };
  }, [state.pageNo, agent.id]);

  const onChangeHandler = async bot => {
    const index = state.checkedBots.indexOf(bot);
    const updatedCheckedList = [...state.checkedBots];

    if (index === -1) {
      updatedCheckedList.push(bot);

      dispatch({
        type: actionDescriptor.ON_CHANGE,
        payload: {
          updatedList: updatedCheckedList,
          bot: bot
        }
      });

      await onAddBot(agent.id, bot.id, bot.name);
    } else {
      updatedCheckedList.splice(index, 1);

      dispatch({
        type: actionDescriptor.ON_CHANGE,
        payload: {
          updatedList: updatedCheckedList,
          bot: bot
        }
      });

      await onRemoveBot(agent.id, bot.id);
    }

    dispatch({
      type: actionDescriptor.ON_CHANGED,
      payload: {
        updatedList: updatedCheckedList
      }
    });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      {/* DIALOG HEADER */}
      {/* <DialogTitle style={{ backgroundColor: '#202447' }}>
        <SearchCmp />
      </DialogTitle> */}

      {/* DIALOG BODY */}
      <List
        dense
        className={classes.root}
        style={{ padding: '10px', height: '200px' }}
      >
        <ToInfinityAndBeyond
          hasMore={state.hasMore}
          loading={state.loading}
          source={state.bots}
          totalCount={state.totalCount}
          renderedComponent={
            <BotListItem state={state} onChange={onChangeHandler} />
          }
          onFetchMore={() => {
            dispatch({
              type: actionDescriptor.ON_FETCH_MORE
            });
          }}
        />
      </List>
    </Dialog>
  );
};
