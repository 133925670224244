import React from 'react';
import { Outlet } from 'react-router-dom';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    backgroundColor: 'red'
  }
});

export const ControlCenterOutlet = props => {
  // const classes = useStyles();
  useStyles();
  
  return (
    <div style={{height:'100%'}} >
      <Outlet />
    </div>
  );
};
