import React from 'react';

export const TailentLogo = props => {
  return (
    <img
      className="logo"
      alt="Logo"
      src="/static/images/tailent.logo.v2.png"
      {...props}
    />
  );
};
