import axios from 'axios';

const { REACT_APP_TAP_NEXUS, REACT_APP_TAP_LICENSING } = process.env;

export const nexusHttpClient = jwtToken =>
  axios.create({
    baseURL: REACT_APP_TAP_NEXUS,
    headers: {
      'CONTENT-TYPE': 'application/json',
      AUTHORIZATION: `Bearer ${jwtToken}`
    }
  });

export const licensingHttpClient = (jwtToken = null) =>
  axios.create({
    baseURL: REACT_APP_TAP_LICENSING,
    headers: {
      'CONTENT-TYPE': 'application/json',
      AUTHORIZATION: `Bearer ${jwtToken}`
    }
  });

axios.interceptors.response.use(
  response => response,
  error => {
    const { status } = error.response;

    switch (status) {
      case 401: {
        window.location = '/login';
      }
      case 500: {
      }
    }
  }
);
