import React from 'react';

import Checkbox from '@material-ui/core/Checkbox';

import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

const sortDirection = {
  ASC: 'asc',
  DESC: 'desc'
};

export const TableHeader = ({
  order,
  orderBy,
  rowCount,
  columns,
  selectedRows,
  onRequestSort,
  onSelectAll,
  ...rest
}) => {
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            indeterminate={selectedRows > 0 && selectedRows < rowCount}
            checked={rowCount > 0 && selectedRows === rowCount}
            onChange={onSelectAll}
            label="checkbox"
          />
        </TableCell> */}
        {columns.map(column =>
          column.sortable ? (
            <TableCell
              key={column.name}
              align={column.align}
              padding="default"
              sortDirection={orderBy === column.name ? order : false}
              style={column.style}
            >
              <TableSortLabel
                active={orderBy === column.name}
                direction={orderBy === column.name ? order : sortDirection.ASC}
                onClick={createSortHandler(column.name)}
              >
                {column.label}
              </TableSortLabel>
            </TableCell>
          ) : (
            <TableCell
              key={column.name}
              align={column.align}
              padding="default"
              style={column.style}
            >
              {column.label}
            </TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  );
};
