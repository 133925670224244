import React from 'react';

export const ToInfinityAndBeyond = ({
  hasMore,
  loading,
  source,
  totalCount,
  renderedComponent,
  onFetchMore,
  ...rest
}) => {
  const observer = React.useRef();
  const lastBotElementRef = React.useCallback(
    node => {
      if (loading) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasMore) {
          onFetchMore();
        }
      });

      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  return (
    <>
      {source.map((element, index) => {
        if (source.length === index + 1) {
          return (
            <div ref={lastBotElementRef} key={index}>
              {React.cloneElement(renderedComponent, {
                ...rest,
                key: index,
                element: element
              })}
            </div>
          );
        } else {
          return (
            <div key={index}>
              {React.cloneElement(renderedComponent, {
                ...rest,
                key: index,
                element: element
              })}
            </div>
          );
        }
      })}
    </>
  );
};
