import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { Formik } from 'formik';
import * as Yup from 'yup';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';

import { RandomLoader } from '../../__common/spinners/global-spinners';

import Page from '../../__common/layouts/Page';
import { TailentLogo } from '../../__common/Logos/tailent.logo';

const { REACT_APP_TAP_LICENSING } = process.env;

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#050A31',
    height: '90%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

export const LoginForm = () => {
  const [loading, setLoading] = React.useState(false);

  const classes = useStyles();
  const navigate = useNavigate();

  const onSubmit = (values, actions) => {
    setLoading(true);

    axios({
      method: 'POST',
      url: `${REACT_APP_TAP_LICENSING}/api/accounts/login`,
      data: values,
      headers: {
        'CONTENT-TYPE': 'application/json'
      }
    })
      .then(response => {
        localStorage.setItem('user', JSON.stringify(response.data));
        navigate('/dashboard', {
          replace: true
        });
      })
      .catch(error => {
        actions.setFieldError('general', 'Something went wrong');
        actions.setSubmitting(false);
      });
  };

  return (
    <>
      {loading ? (
        <div
          style={{ backgroundColor: '#050a31', width: '100%', height: '100%' }}
        >
          <div
            style={{
              width: 'fit-content',
              padding: '0 24px',
              paddingTop: '20px'
            }}
          >
            <TailentLogo />
            <Typography style={{ color: '#fff', fontSize: '1.6rem' }}>
              <span style={{ fontWeight: 900 }}>TAP </span>
              <span>Orchestrator</span>
            </Typography>
          </div>

          <RandomLoader />
        </div>
      ) : (
        <Page className={classes.root} title="Login">
          <div
            style={{
              width: 'fit-content',
              marginTop: '-5px',
              padding: '0 24px'
            }}
          >
            <TailentLogo />
            <Typography style={{ color: '#fff', fontSize: '1.6rem' }}>
              <span style={{ fontWeight: 900 }}>TAP </span>
              <span>Orchestrator</span>
            </Typography>
          </div>
          <Box
            display="flex"
            flexDirection="column"
            height="100%"
            justifyContent="center"
          >
            <Container maxWidth="sm">
              <Formik
                initialValues={{
                  email: '',
                  password: ''
                }}
                validationSchema={Yup.object().shape({
                  email: Yup.string()
                    .email('Must be a valid email')
                    .max(255)
                    .required('Email is required'),
                  password: Yup.string()
                    .max(255)
                    .required('Password is required')
                })}
                onSubmit={onSubmit}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Box mb={3}>
                      <Typography color="textPrimary" variant="h2">
                        Nexus sign in
                      </Typography>
                    </Box>

                    <TextField
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      label="Email Address"
                      margin="normal"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="email"
                      value={values.email}
                      variant="outlined"
                    />

                    <TextField
                      error={Boolean(touched.password && errors.password)}
                      fullWidth
                      helperText={touched.password && errors.password}
                      label="Password"
                      margin="normal"
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="password"
                      value={values.password}
                      variant="outlined"
                    />

                    <Typography style={{ color: 'red' }}>
                      {errors.general}
                    </Typography>

                    <Box my={2}>
                      <Button
                        color="primary"
                        style={{ backgroundColor: '#C05EC3' }}
                        disabled={isSubmitting}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        Sign in
                      </Button>
                    </Box>

                    <Typography style={{ color: '#fff' }} variant="body1">
                      <Link
                        to="/reset-password-confirm"
                        style={{ color: '#C05EC3' }}
                      >
                        Forgot password?
                      </Link>
                    </Typography>

                    <Typography style={{ color: '#fff' }} variant="body1">
                      <a
                        href="https://nexus.tailent.com/"
                        style={{ color: '#C05EC3' }}
                      >
                        Sign up
                      </a>
                    </Typography>
                  </form>
                )}
              </Formik>
            </Container>
          </Box>
        </Page>
      )}
    </>
  );
};
