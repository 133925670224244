import React from 'react';

import * as Yup from 'yup';
import { Formik } from 'formik';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';

import Page from '../../__common/layouts/Page';
import { TailentLogo } from '../../__common/Logos/tailent.logo';

import { useQuery } from '../../../hooks/useQuery';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#050A31',
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

export const ResetPasswordForm = () => {
  const classes = useStyles();

  const queryParams = useQuery();
  const token = queryParams.get('token');
  const email = queryParams.get('email');

  const onSubmit = ({
    password,
    confirmPassword,
    setSubmitting,
    resetForm
  }) => {
    setSubmitting(false);
    axios({
      method: 'PATCH',
      url: `${process.env.REACT_APP_TAP_LICENSING}/api/accounts/reset-password`,
      data: {
        email: email,
        token: token,
        newPassword: password,
        confirmationPassword: confirmPassword
      },
      headers: {
        'CONTENT-TYPE': 'application/json'
      }
    })
      .then(response => {
        setSubmitting(() => ({
          passChangeSuccess: true
        }));

        window.location = '/login';
      })
      .catch(error => {
        resetForm();
      });
  };

  return (
    <Page className={classes.root} title="Login">
      <div
        style={{
          width: 'fit-content',
          marginTop: '-5px',
          padding: '0 24px'
        }}
      >
        <TailentLogo />
        <Typography style={{ color: '#fff', fontSize: '1.6rem' }}>
          <span style={{ fontWeight: 900 }}>TAP </span>
          <span>Orchestrator</span>
        </Typography>
      </div>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              password: '',
              confirmPassword: ''
            }}
            validationSchema={Yup.object().shape({
              password: Yup.string()
                .min(6)
                .required('New password is required'),

              confirmPassword: Yup.string()
                .min(6)
                .oneOf([Yup.ref('password')], 'Passwords do not match')
                .required('Confirm password is required')
            })}
            onSubmit={(
              { password, confirmPassword },
              { setSubmitting, resetForm }
            ) =>
              onSubmit({ password, confirmPassword, setSubmitting, resetForm })
            }
          >
            {({
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              isValid,
              isSubmitting
            }) => (
              <form onSubmit={handleSubmit}>
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />

                <TextField
                  error={Boolean(
                    touched.confirmPassword && errors.confirmPassword
                  )}
                  fullWidth
                  helperText={touched.confirmPassword && errors.confirmPassword}
                  label="Confirm Password"
                  margin="normal"
                  name="confirmPassword"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.confirmPassword}
                  variant="outlined"
                />

                <Typography style={{ color: 'red' }}>
                  {errors.general}
                </Typography>

                <Box my={2}>
                  <Button
                    color="primary"
                    style={{ backgroundColor: '#C05EC3' }}
                    disabled={Boolean(!isValid || isSubmitting)}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    {'Reset'}
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};
