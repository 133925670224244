import React from 'react';

import PauseCircleFilledOutlinedIcon from '@material-ui/icons/PauseCircleFilledOutlined';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';

import { AgentStatus } from './utils';

export const AgentStatusCmp = ({ status, runningBotName }) => {
  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {AgentStatus.Idle === status ? (
          <PauseCircleFilledOutlinedIcon
            style={{ color: '#202447', marginRight: '5px' }}
          />
        ) : (
          <CheckCircleRoundedIcon
            style={{ color: '#32D74C', marginRight: '5px' }}
          />
        )}
        {runningBotName}  {status}
      </div>
    </>
  );
};
