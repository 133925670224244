import React from 'react';

import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import botImg from '../../../../icons/Robo_Active.png';

export const AssignedBotsCmp = ({
  agent,
  selectedBot,
  onRemoveBot,
  ...rest
}) => {
  return agent.bots.map(bot => {
    return (
      <Chip
        style={{
          fontSize: '12px',
          backgroundColor: '#202447',
          margin: '10px 5px'
        }}
        key={bot.id}
        icon={
          <img className="botImg-small" src={botImg} alt="Robo active icon" />
        }
        label={bot.name}
        onDelete={() => onRemoveBot(agent.id, bot.id)}
        color="secondary"
        deleteIcon={
          <IconButton
            style={{ color: '#fff' }}
            disabled={
              (selectedBot.id === bot.id &&
                selectedBot.guard &&
                selectedBot.agentId === agent.id) ||
              bot.isRunning
            }
          >
            <CancelIcon />
          </IconButton>
        }
      />
    );
  });
};
