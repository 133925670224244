import React from 'react';

import PauseCircleFilledOutlinedIcon from '@material-ui/icons/PauseCircleFilledOutlined';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';

export const BotStatusCmp = ({ agents }) => {
  return agents.map(agent => (
    <div key={"R" + agent.id}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {Boolean(agent.isRunning) ? (
          <CheckCircleRoundedIcon
            style={{ color: '#32D74C', marginRight: '5px' }}
          />
        ) : (
          <PauseCircleFilledOutlinedIcon
            style={{ color: '#202447', marginRight: '5px' }}
          />
        )}
       <div> {agent.name} <br/>{Boolean(agent.isRunning) ? 'Running' : 'Idle'}</div>
      </div>
    </div>
  ));
};
