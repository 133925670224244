/* eslint-disable */
import React from 'react';

import { ToastContainer, toast } from 'react-toastify';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
// import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';

import { nexusHttpClient } from '../../../../app/services/httpClients';
import { useUserContext } from '../../../hooks/useUserContext';

import { RandomLoader } from '../../__common/spinners/global-spinners';

import { TablePaging } from '../../__common/table/table.paging.cmp';
import { TableToolbar } from '../../__common/table/table.toolbar.cmp';
import { TableHeader } from '../../__common/table/table.header.cmp';

import { AssignToAgentBtn } from './bot-tbl.add-agent';
import { AssignedToAgentsCmp } from './bot-tbl.bot-agents';
import { ActionsMainMoreMenuCmp } from './bot-tbl.more-menu';
import { BotStatusCmp } from './bot-tbl.status';

import ActiveBotImg from '../../../../icons/Robo_Active.png';
import InactiveBotImg from '../../../../icons/Robo_Idle.png';

const sortDirection = {
  ASC: 'asc',
  DESC: 'desc'
};

const tblColumns = [
  {
    name: 'name',
    label: 'Name',
    numeric: false,
    disablePadding: true,
    align: 'left',
    sortable: true
  },
  {
    name: 'version',
    label: 'Version',
    numeric: true,
    disablePadding: false,
    align: 'left',
    sortable: true
  },
  {
    name: 'agents',
    label: 'Agents',
    numeric: false,
    disablePadding: true,
    align: 'left',
    sortable: false
  },
  {
    name: 'status',
    label: 'Status',
    numeric: false,
    disablePadding: true,
    align: 'left',
    sortable: false
  },
  {
    name: 'actions',
    label: 'Actions',
    numeric: false,
    disablePadding: false,
    align: 'left',
    sortable: false
  }
];

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) return -1;
  if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) return 1;
  return 0;
};

const getComparator = (order, column) =>
  order === 'desc'
    ? (a, b) => descendingComparator(a, b, column)
    : (a, b) => -descendingComparator(a, b, column);

const sortBy = (array, comparator) =>
  array
    .map((rowItem, index) => {
      return [rowItem, index];
    })
    .sort((item_1, item_2) => {
      const order = comparator(item_1[0], item_2[0]);
      return order !== 0 ? order : item_1[1] - item_2[1];
    })
    .map(el => {
      return el[0];
    });

const actionDescriptor = {
  ON_FETCHED: 'ON_FETCHED',

  ON_PAGE_CHANGE: 'ON_PAGE_CHANGE',
  ON_PAGE_SIZE_CHANGE: 'ON_PAGE_SIZE_CHANGE',

  ON_FILTER_CHANGE: 'ON_FILTER_CHANGE',

  ON_ROW_SELECT: 'ON_ROW_SELECT',
  ON_ROWS_SELECT: 'ON_ROWS_SELECT',
  ON_ROWS_UNSELECT: 'ON_ROWS_UNSELECT',

  ON_COLUMN_SORT: 'ON_COLUMN_SORT',

  ON_AGENT_ADDED: 'ON_AGENT_ADDED',

  ON_REMOVE_AGENT: 'ON_REMOVE_AGENT',
  ON_AGENT_REMOVED: 'ON_AGENT_REMOVED',

  ON_BOT_STOPED: 'ON_BOT_STOPED',
  ON_BOT_STARTED: 'ON_BOT_STARTED'
};

const initialState = {
  loading: true,

  orderColumn: {
    name: 'name',
    direction: sortDirection.ASC
  },

  filter: '',

  pageNo: 1,
  pageSize: 5,
  pageCount: 1,
  pageSizeOptions: [5, 10, 25],

  bots: [],
  count: 0,
  selected: [],

  /** state used to block multiple click events when assigning  */
  selectedAgent: {},

  /** state used to display available bots for bind|unbind */
  allAgents: [],

  statusCode: 200,
  error: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionDescriptor.ON_FETCHED: {
      return {
        ...state,
        loading: false,
        bots: action.payload.bots,
        count: action.payload.count,
        pageCount: Math.ceil(action.payload.count / state.pageSize)
      };
    }

    case actionDescriptor.ON_PAGE_CHANGE: {
      return {
        ...initialState,
        pageNo: action.payload,
        filter: state.filter,
        pageSize: state.pageSize
      };
    }
    case actionDescriptor.ON_PAGE_SIZE_CHANGE: {
      return {
        ...initialState,
        pageSize: action.payload.size,
        filter: state.filter
      };
    }

    case actionDescriptor.ON_FILTER_CHANGE: {
      return {
        ...state,
        pageNo: initialState.pageNo,
        selected: initialState.selected,
        filter: action.payload
      };
    }

    case actionDescriptor.ON_ROW_SELECT: {
      return {
        ...state,
        selected: action.payload
      };
    }
    case actionDescriptor.ON_ROWS_SELECT: {
      return {
        ...state,
        selected: action.payload
      };
    }
    case actionDescriptor.ON_ROWS_UNSELECT: {
      return {
        ...state,
        selected: action.payload
      };
    }

    case actionDescriptor.ON_COLUMN_SORT: {
      return {
        ...state,
        orderColumn: {
          name: action.payload.name,
          direction: action.payload.direction
        }
      };
    }

    case actionDescriptor.ON_AGENT_ADDED: {
      const bot = state.bots.find(bot => bot.id === action.payload.botId);

      bot.agents.push({
        id: action.payload.agentId,
        name: action.payload.agentName,
        isRunning: false
      });
      bot.agentCount = bot.agents.length;

      return {
        ...state
      };
    }

    case actionDescriptor.ON_REMOVE_AGENT: {
      return {
        ...state,
        selectedAgent: {
          id: action.payload.agentId,
          botId: action.payload.botId,
          guard: action.payload.guard
        }
      };
    }
    case actionDescriptor.ON_AGENT_REMOVED: {
      const bot = state.bots.find(bot => bot.id === action.payload.botId);

      const newBotList = bot.agents.filter(
        item => item.id !== action.payload.agentId
      );

      bot.agents = newBotList;
      bot.agentCount = bot.agents.length;

      return {
        ...state
      };
    }

    case actionDescriptor.ON_BOT_STOPED: {
      const bot = state.bots.find(bot => bot.id === action.payload.botId);

      bot.agents.forEach(agent => {
        if (agent.id === action.payload.agentId) {
          agent.isRunning = false;
        }
      });

      bot.isRunning = bot.agents.some(agent => agent.isRunning);

      return {
        ...state
      };
    }
    case actionDescriptor.ON_BOT_STARTED: {
      const bot = state.bots.find(bot => bot.id === action.payload.botId);

      bot.agents.forEach(agent => {
        if (agent.id === action.payload.agentId) {
          agent.isRunning = true;
        }
      });

      bot.isRunning = bot.agents.some(agent => agent.isRunning);

      return {
        ...state
      };
    }
  }
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.main
  },

  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.main
  },
  table: {
    minWidth: 750,
    fontSize: '16px'
  },

  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },

  pagionationRoot: {
    flexGrow: 1,
    marginBottom: '2rem'
  }
}));

export const BotTblCmp = () => {
  const classes = useStyles();
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const { accessToken } = useUserContext();
  const [counter, setReload] = React.useState(0);

  React.useEffect(() => {
    nexusHttpClient(accessToken)
      .get(
        `api/bots?skip=${(state.pageNo - 1) * state.pageSize}&take=${
          state.pageSize
        }&filter=${state.filter}`
      )
      .then(response => {
        const bots = response.data.value;

        dispatch({
          type: actionDescriptor.ON_FETCHED,
          payload: {
            bots: bots,
            count: response.data.count
          }
        });
      })
      .catch(error => {
        // alert(error);
      });
  }, [state.pageNo, state.pageSize, state.filter]);

  const onCallBack = (p) =>
  {
    nexusHttpClient(accessToken)
      .get(
        `api/bots?skip=${(state.pageNo - 1) * state.pageSize}&take=${
          state.pageSize
        }&filter=${state.filter}`
      )
      .then(response => {
        const bots = response.data.value;

        dispatch({
          type: actionDescriptor.ON_FETCHED,
          payload: {
            bots: bots,
            count: response.data.count
          }
        });
      })
      .catch(error => {
        // alert(error);
      });
  };

  const onPageChange = (event, page) => {
    if (state.pageNo === page) return;
    dispatch({
      type: actionDescriptor.ON_PAGE_CHANGE,
      payload: page
    });
  };

  const onPageSizeChange = event => {
    dispatch({
      type: actionDescriptor.ON_PAGE_SIZE_CHANGE,
      payload: {
        size: parseInt(event.target.value, 10)
      }
    });
  };

  const onOrderColumn = (event, property) => {
    const isAsc =
      state.orderColumn.name === property &&
      state.orderColumn.direction === sortDirection.ASC;
    dispatch({
      type: actionDescriptor.ON_COLUMN_SORT,
      payload: {
        name: property,
        direction: isAsc ? sortDirection.DESC : sortDirection.ASC
      }
    });
  };

  const onRowSelected = (event, id) => {
    const selectedIndex = state.selected.indexOf(id);
    let selected = [];
    if (selectedIndex === -1) {
      selected = selected.concat(state.selected, id);
    } else if (selectedIndex === 0) {
      selected = selected.concat(state.selected.slice(1));
    } else if (selectedIndex === state.selected.length - 1) {
      selected = selected.concat(state.selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      selected = selected.concat(
        state.selected.slice(0, selectedIndex),
        state.selected.slice(selectedIndex + 1)
      );
    }
    dispatch({
      type: actionDescriptor.ON_ROW_SELECT,
      payload: selected
    });
  };

  const onAllRowsSelected = event => {
    if (event.target.checked) {
      dispatch({
        type: actionDescriptor.ON_ROWS_SELECT,
        payload: state.bots.map(row => row.id)
      });
    } else {
      dispatch({
        type: actionDescriptor.ON_ROWS_UNSELECT,
        payload: []
      });
    }
  };

  const isSelected = id => {
    return state.selected.indexOf(id) !== -1;
  };

  const onChangeFilter = input => {
    dispatch({
      type: actionDescriptor.ON_FILTER_CHANGE,
      payload: input
    });
  };

  const onRemoveAgentHandler = async (botId, agentId) => {
    dispatch({
      type: actionDescriptor.ON_REMOVE_AGENT,
      payload: {
        botId: botId,
        agentId: agentId,
        guard: true
      }
    });

    try {
      await nexusHttpClient(accessToken).delete(
        `api/agents/${agentId}/bots/${botId}/dissociate`
      );

      dispatch({
        type: actionDescriptor.ON_AGENT_REMOVED,
        payload: {
          botId: botId,
          agentId: agentId
        }
      });
    } catch (error) {
      // alert(error);
    }
  };

  const onAddAgentHandler = async (botId, agentId, agentName) => {
    try {
      const response = await nexusHttpClient(accessToken).post(
        `api/agents/${agentId}/bots/associate`,
        botId
      );

      dispatch({
        type: actionDescriptor.ON_AGENT_ADDED,
        payload: {
          botId: botId,
          agentId: agentId,
          agentName: agentName
        }
      });
    } catch (error) {
      // alert(error);
    }
  };

  const onStopBotHandler = (botId, agentId) => {
    nexusHttpClient(accessToken)
      .put(`api/agents/${agentId}/cancel-bot-execution`, { botId: botId })
      .then(response => {
        dispatch({
          type: actionDescriptor.ON_BOT_STOPED,
          payload: {
            botId: botId,
            agentId: agentId
          }
        });
      })
      .catch(error => {
        // alert(error);
      });
  };

  const onStartBotHandler = (botId, agentId) => {
    nexusHttpClient(accessToken)
      .put(`api/agents/${agentId}/start-bot`, { botId: botId })
      .then(response => {
        dispatch({
          type: actionDescriptor.ON_BOT_STARTED,
          payload: {
            agentId: agentId,
            botId: botId
          }
        });
      })
      .catch(error => {
        /// https://github.com/fkhadra/react-toastify/issues/423
        ///
        toast.warn(' Bot could not be started! ', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      });
  };

  return (
    <>
      {state.loading ? (
        <RandomLoader />
      ) : (
        <div style={{ background: '#202447' }}>
          <ToastContainer />
          <Typography className="pageTitle">Bots</Typography>

          <div>
            <Paper className={'table'}>
              <TableToolbar
                selected={state.selected.length}
                defaultValue={state.filter}
                onChangeFilter={onChangeFilter}
              />

              <TableContainer>
                <Table
                  aria-labelledby="tableTitle"
                  size="medium"
                  aria-label="enhanced table"
                >
                  <TableHeader
                    columns={tblColumns}
                    classes={classes}
                    selected={state.selected.length}
                    order={state.orderColumn.direction}
                    orderBy={state.orderColumn.name}
                    onSelectAll={onAllRowsSelected}
                    onRequestSort={onOrderColumn}
                    rowCount={state.bots.length}
                  />

                  <TableBody>
                    {sortBy(
                      state.bots,
                      getComparator(
                        state.orderColumn.direction,
                        state.orderColumn.name
                      )
                    ).map((bot, index) => {
                      const isItemSelected = isSelected(bot.id);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          hover
                          onClick={event => onRowSelected(event, bot.id)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={bot.id}
                          selected={isItemSelected}
                        >
                          {/* <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </TableCell> */}

                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="default"
                            align="left"
                            style={{ fontSize: '16px', height: 100 }}
                          >
                            {Boolean(bot.isRunning) === true ? (
                              <img
                                className="botImg"
                                src={ActiveBotImg}
                                alt="Robo active icon"
                              />
                            ) : (
                              <img
                                className="botImg"
                                src={InactiveBotImg}
                                alt="Robo inactive icon"
                              />
                            )}
                            {bot.name}
                          </TableCell>

                          <TableCell
                            align="inherit"
                            style={{ fontSize: '16px' }}
                          >
                            {bot.version}
                          </TableCell>

                          <TableCell align="left">
                            <AssignToAgentBtn
                              bot={bot}
                              onAddAgent={onAddAgentHandler}
                              onRemoveAgent={onRemoveAgentHandler}
                            />

                            <AssignedToAgentsCmp
                              bot={bot}
                              selectedAgent={state.selectedAgent}
                              onRemoveAgent={onRemoveAgentHandler}
                            />
                          </TableCell>

                          <TableCell align="left" style={{ fontSize: '16px' }}>
                            <BotStatusCmp agents={bot.agents}/>
                          </TableCell>

                          <TableCell align="left">
                            <ActionsMainMoreMenuCmp
                              key={bot.id}
                              bot={bot}
                              // disabled={bot.agents.length === 0}
                              onStopBot={onStopBotHandler}
                              onStartBot={onStartBotHandler}
                              onRemoveAgent={onRemoveAgentHandler}
                              callback={onCallBack}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>

              <TablePagination
                className={'pagination'}
                component="div"
                count={state.count}
                rowsPerPage={state.pageSize}
                rowsPerPageOptions={state.pageSizeOptions}
                page={state.pageNo - 1}
                labelDisplayedRows={({ from, to, count }) =>
                  `Showing ${from} to ${to} of ${count}`
                }
                onChangeRowsPerPage={onPageSizeChange}
                nextIconButtonProps={{ style: { visibility: 'hidden' } }}
                backIconButtonProps={{ style: { visibility: 'hidden' } }}
                onChangePage={(e, page) => {}}
              />

              <TablePaging
                pageNo={state.pageNo}
                pageCount={state.pageCount}
                onChange={onPageChange}
                linkTo={'/control-center/bots'}
              />
            </Paper>
          </div>
        </div>
      )}
    </>
  );
};
