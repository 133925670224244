import React from 'react';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import IconButton from '@material-ui/core/IconButton';

export const ActionsInnerMoreMenuActions = ({
  isRunning,
  onStop,
  onStart,
  onRemoveAgent,
  ...rest
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const menuActions = [
    {
      id: '1',
      label: 'Remove',
      disable: isRunning,
      onClickHandler: () => {
        onRemoveAgent();
        setAnchorEl(null);
      }
    },
    {
      id: '2',
      label: 'Stop',
      disable: !isRunning,
      onClickHandler: () => {
        onStop();
        setAnchorEl(null);
      }
    },
    {
      id: '3',
      label: 'Start',
      disable: isRunning,
      onClickHandler: () => {
        onStart();
        setAnchorEl(null);
      }
    }
  ];

  return (
    <div>
      {/* VERTICAL MENU */}
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={event => {
          setAnchorEl(event.currentTarget);
        }}
      >
        <MoreVertIcon />
      </IconButton>

      {/* VERTICAL MENU ACTIONS */}
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          style: {
            width: '15ch'
          }
        }}
      >
        {menuActions.map(action => (
          <MenuItem
            key={action.id}
            value={action.label}
            disabled={action.disable}
            onClick={() => action.onClickHandler()}
          >
            {action.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
