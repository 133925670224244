import React from 'react';
import { Navigate } from 'react-router-dom';

import { Dashboard } from './app/components/dashboard/main.component';

import { MainOutlet } from './app/components/mainOutlet.component';
import { LeftSideNavOutlet } from './app/components/leftSideNav/main.component';

/// TODO : remove this
import { ControlCenterOutlet } from './app/components/control-center/controlCenterOutlet.component';

import AgentCmp from '../src/app/components/control-center/agents/main.component';
import BotCmp from '../src/app/components/control-center/bots/main.component';

import { LoginForm } from './app/components/account/login/user-login.form';
import { Account } from './app/components/account/profile/user.profile.component';
import { ResetPasswordForm } from './app/components/account/password/reset-password.form';
import { SendResetEmailForm } from './app/components/account/password/reset-password-request.form';

import NotFoundView from '../src/app/components/__common/4xx/404.component';
import CompanyScheduler from "./app/components/__common/companyScheduler/companyScheduler";
import CompanyScheduler2 from "./app/components/__common/companyScheduler/companyScheduler2";

const routes = [
  {
    path: '',
    element: <LeftSideNavOutlet />,
    children: [
      { path: 'dashboard', element: <Dashboard /> },
      { path: 'account', element: <Account /> },
      {
        path: 'scheduler2',
        element: <CompanyScheduler2 />,
      },
      {
        path: 'scheduler',
        element: <CompanyScheduler />,
      },
      {
        path: 'control-center/',
        element: <ControlCenterOutlet />,
        children: [
          { path: '/', element: <Navigate to="bots" /> },
          { path: 'bots', element: <BotCmp /> },
          { path: 'agents', element: <AgentCmp /> },

        ]
      },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainOutlet />,
    children: [
      { path: 'login', element: <LoginForm /> },
      { path: 'reset-password', element: <ResetPasswordForm /> },
      { path: 'reset-password-confirm', element: <SendResetEmailForm /> },
      { path: '404', element: <NotFoundView /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },

];

export default routes;
