import React from 'react';

import { Doughnut } from 'react-chartjs-2';
import Typography from '@material-ui/core/Typography';

import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ComputerIcon from '@material-ui/icons/Computer';

import { useUserContext } from '../../hooks/useUserContext';

import axios from 'axios';

const actionDescriptor = {
  ON_FETCHED: 'ON_FETCHED'
};

const initialState = {
  loading: true,
  chart: {
    labels: ['Active', 'Inactive'],
    datasets: [
      {
        backgroundColor: ['#5d79bf', '#C05EC3', '#ccc'],
        hoverBackgroundColor: ['#7192e7', '#ec74ef', '#eaeaea'],
        data: [100, 100],
        borderWidth: 0,
        borderRadius: 50,
        circumference: 30
      }
    ]
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionDescriptor.ON_FETCHED: {
      const data = state.chart.datasets[0].data;
      data.splice(
        0,
        data.length,
        action.payload.countActive,
        action.payload.countInctive
      );
      return {
        ...state,
        loading: false
      };
    }
  }
};

const styles = {
  pieContainer: {
    width: '40%',
    height: '40%',
    marginTop: 20,
    position: 'relative'
  },
  relative: {
    position: 'relative'
  }
};

export const AgentsDoughnutChart = props => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const { accessToken } = useUserContext();

  React.useEffect(() => {
    axios({
      method: 'GET',
      url: `${process.env.REACT_APP_TAP_NEXUS}/api/organizations/agents`,
      headers: {
        'CONTENT-TYPE': 'application/json',
        AUTHORIZATION: `Bearer ${accessToken}`
      }
    })
      .then(response => {
        dispatch({
          type: actionDescriptor.ON_FETCHED,
          payload: {
            countActive: response.data.value.countActive,
            countInctive: response.data.value.countInactive
          }
        });
      })
      .catch(error => {
        // alert(error);
      });
  }, []);

  return (
    <>
      {state.loading ? (
        <></>
      ) : (
        <div style={styles.pieContainer}>
          <ComputerIcon
            style={{
              top: '50%',
              left: '50%',
              position: 'absolute',
              transform: 'translate(-50%, -50%)',
              marginTop: '10px',
              width: '30px',
              height: '30px',
              color: '#fff'
            }}
          />
          <Doughnut
            data={state.chart}
            options={{
              legend: {
                display: false,
                position: 'right'
              },
              cutoutPercentage: 70
            }}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
              marginTop: '10px'
            }}
          >
            <Typography
              style={{
                color: '#fff',
                fontSize: '14px',
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center'
              }}
            >
              {' '}
              <FiberManualRecordIcon
                style={{
                  color: '#5d79bf',
                  fontSize: '16px',
                  marginRight: '5px'
                }}
              />
              Active
            </Typography>
            <Typography
              style={{
                color: '#fff',
                fontSize: '14px',
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center'
              }}
            >
              <FiberManualRecordIcon
                style={{
                  color: '#C05EC3',
                  fontSize: '16px',
                  marginRight: '5px'
                }}
              />
              Inactive
            </Typography>
          </div>
        </div>
      )}
    </>
  );
};
