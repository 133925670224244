import React from 'react';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import Typography from '@material-ui/core/Typography';

import IdleBotImg from '../../../icons/Robo_Idle.png';

import { useUserContext } from '../../hooks/useUserContext';

import axios from 'axios';

const actionDescriptor = {
  ON_FETCHED: 'ON_FETCHED'
};

const initialState = {
  loading: true,
  total: 0,
  totalSucceed: 0,
  totalFailed: 0
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionDescriptor.ON_FETCHED: {
      return {
        loading: false,
        total: action.payload.total,
        totalSucceed: action.payload.totalSucceed,
        totalFailed: action.payload.totalFailed
      };
    }
  }
};

export const UnassignedBots = props => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const { accessToken } = useUserContext();

  React.useEffect(() => {
    axios({
      method: 'GET',
      url: `${process.env.REACT_APP_TAP_NEXUS}/api/organizations/unassigned-bots`,
      headers: {
        'CONTENT-TYPE': 'application/json',
        AUTHORIZATION: `Bearer ${accessToken}`
      }
    })
      .then(response => {
        dispatch({
          type: actionDescriptor.ON_FETCHED,
          payload: {
            total: response.data.count
          }
        });
      })
      .catch(error => {
        // alert(error);
      });
  }, []);

  return (
    <>
      <div className={'botImgContainer'}>
        <img
          className="botImg-large"
          src={IdleBotImg}
          alt="Robo inactive icon"
        />
        <Typography className={'botNo'}>{state.total}</Typography>
      </div>

      <div>
        <button className={'botsBtn'}>
          Not assigned <ChevronRightIcon style={{ color: '#C05EC3' }} />
        </button>
      </div>
      {/* <div className={'runningContainer'}>
        <CheckCircleRoundedIcon
          style={{ color: '#32D74C', marginRight: '5px' }}
        />
        <Typography style={{ color: '#fff', fontSize: '14px' }}>
          3 Running
        </Typography>
      </div> */}
    </>
  );
};
