/* eslint-disable */
import React from 'react';

import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Typography from '@material-ui/core/Typography';
import WarningIcon from '@material-ui/icons/Warning';

import { useUserContext } from '../../hooks/useUserContext';

import axios from 'axios';

import botImg from '../../../icons/Robo_Active.png';

const actionDescriptor = {
  ON_FETCHED: 'ON_FETCHED'
};

const initialState = {
  loading: true,
  totalCount: 0,
  totalSucceed: 0,
  totalFailed: 0
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionDescriptor.ON_FETCHED: {
      return {
        loading: false,
        totalCount: action.payload.totalCount,
        totalSucceed: action.payload.totalSucceed,
        totalFailed: action.payload.totalFailed
      };
    }
  }
};

export const BotsExecuted = props => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const { accessToken } = useUserContext();

  React.useEffect(() => {
    axios({
      method: 'GET',
      url: `${process.env.REACT_APP_TAP_NEXUS}/api/organizations/executed-bots`,
      headers: {
        'CONTENT-TYPE': 'application/json',
        AUTHORIZATION: `Bearer ${accessToken}`
      }
    })
      .then(response => {
        dispatch({
          type: actionDescriptor.ON_FETCHED,
          payload: {
            totalCount: response.data.count,
            totalSucceed: response.data.value.countSucceed,
            totalFailed: response.data.value.countFailed
          }
        });
      })
      .catch(error => {
        // alert(error);
      });
  }, []);

  return (
    <>
      <div className={'botImgContainer'}>
        <img className="botImg-large" src={botImg} alt="Robo active icon" />
        <Typography className={'botNo'}>{state.totalCount}</Typography>
      </div>
      <div>
        <button className={'botsBtn'}>
          Bots executed
          <ChevronRightIcon style={{ color: '#C05EC3' }} />
        </button>
      </div>
      <div className={'executedInfo'}>
        <div className={'runningContainer'} style={{ marginRight: '10px' }}>
          <CheckCircleRoundedIcon
            style={{ color: '#32D74C', marginRight: '5px', fontSize: '16px' }}
          />
          <Typography style={{ color: '#fff', fontSize: '14px' }}>
            {state.totalSucceed} Succeed
          </Typography>
        </div>

        <div className={'runningContainer'}>
          <WarningIcon
            style={{ color: '#f2a23c', marginRight: '5px', fontSize: '16px' }}
          />
          <Typography style={{ color: '#fff', fontSize: '14px' }}>
            {state.totalFailed} Failed
          </Typography>
        </div>
      </div>
    </>
  );
};
