/* eslint-disable */
import React from 'react';

import ComputerIcon from '@material-ui/icons/Computer';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Typography from '@material-ui/core/Typography';
import WarningIcon from '@material-ui/icons/Warning';

import { useUserContext } from '../../hooks/useUserContext';

import axios from 'axios';

const actionDescriptor = {
  ON_FETCHED: 'ON_FETCHED'
};

const initialState = {
  loading: true,
  totalCount: 0,
  totalActive: 0,
  totalInactive: 0
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionDescriptor.ON_FETCHED: {
      return {
        loading: false,
        totalCount: action.payload.totalCount,
        totalActive: action.payload.totalActive,
        totalInactive: action.payload.totalInactive
      };
    }
  }
};

export const AgentsPanel = props => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const { accessToken } = useUserContext();

  React.useEffect(() => {
    axios({
      method: 'GET',
      url: `${process.env.REACT_APP_TAP_NEXUS}/api/organizations/agents`,
      headers: {
        'CONTENT-TYPE': 'application/json',
        AUTHORIZATION: `Bearer ${accessToken}`
      }
    })
      .then(response => {
        dispatch({
          type: actionDescriptor.ON_FETCHED,
          payload: {
            totalCount: response.data.count,
            totalActive: response.data.value.countActive,
            totalInactive: response.data.value.countInactive
          }
        });
      })
      .catch(error => {
        // alert(error);
      });
  }, []);

  return (
    <>
      <div className={'botImgContainer'}>
        <ComputerIcon
          style={{ fontSize: '65px', color: '#fff', marginRight: '10px' }}
        />
        <Typography className={'botNo'}>{state.totalCount}</Typography>
      </div>

      <div>
        <button className={'botsBtn'}>
          Agents <ChevronRightIcon style={{ color: '#C05EC3' }} />
        </button>
      </div>
      <div className={'executedInfo'}>
        <div className={'runningContainer'} style={{ marginRight: '10px' }}>
          <CheckCircleRoundedIcon
            style={{ color: '#32D74C', marginRight: '5px', fontSize: '16px' }}
          />
          <Typography style={{ color: '#fff', fontSize: '14px' }}>
            {state.totalActive} Active
          </Typography>
        </div>
        <div className={'runningContainer'}>
          <WarningIcon
            style={{ color: '#f2a23c', marginRight: '5px', fontSize: '16px' }}
          />
          <Typography style={{ color: '#fff', fontSize: '14px' }}>
            {state.totalInactive} Inactive
          </Typography>
        </div>
      </div>
    </>
  );
};
