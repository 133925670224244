import React from 'react';
import { Box, Container, Typography, makeStyles } from '@material-ui/core';
import Page from '../layouts/Page';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  image: {
    marginTop: 50,
    display: 'inline-block',
    maxWidth: '100%',
    width: 560
  }
}));

const ForbiddenView = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Not authorized for Orchestration services.">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="md">
          <Typography align="center" color="textPrimary" variant="h4">
            Your organization is not authorized for using TAP Orchestrator Services.
          </Typography>
         <Box textAlign="center"> 
            { <img
              alt="Under development"
              className={classes.image}
              color='red'
              src="/static/images/403-forbidden.png"
              style={{ tintColor: "white",}}
            /> }
          </Box>
        </Container>
      </Box>
    </Page>
  );
};

export default ForbiddenView;
